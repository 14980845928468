// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { carouselTypeIcon, fbIcon, instaIcon, postsTypeIcon, reelsTypeIcon, storiesTypeIcon, ticktockIcon, xIcon } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";


export const configJSON = require("./config.js");

interface AccountData {
    id: string;
    type: string;
}

interface AccountRelationship {
    data: AccountData;
}

interface CampaignAttributes {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

interface CampaignData {
    id: string;
    type: string;
    attributes: CampaignAttributes;
    relationships: {
        account: AccountRelationship;
    };
}

export interface Props {
    navigation: any;

}
interface S {
    socialMediaData: {
        id: number;
        name: string;
        icon: string;
        connected: boolean;
    }[],
    postTypeData: {
        id: number;
        name: string;
        icon: string;
        color: string;
        types: {
            typeName: string;
            typeIcon: string;
            isSelected: boolean;
        }[]
    }[],
    postTypeSelected: boolean;
    pageName: string;
    aboutSelectValue: string;
    whatAboutContent: string;
    authToken: string;
    userId: string;
    campaignData: CampaignData[];
    searchValue: string;
    selectedCampaignId: string;
    name: string;
}
interface SS { }

export default class GuidedWalkthroughController extends BlockComponent<Props, S, SS> {
    getCampaignDataApiCallId: string = '';
    generatePostApiCallId: string = '';

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            socialMediaData: [
                { id: 2, name: "Instagram", icon: instaIcon, connected: false },
            ],
            postTypeData: [
                {
                    id: 1,
                    name: 'Instagram',
                    icon: instaIcon,
                    color: '#7153B8',
                    types: [
                        { typeName: 'Image posts', typeIcon: postsTypeIcon, isSelected: false },
                        { typeName: 'Carousel', typeIcon: carouselTypeIcon, isSelected: false },
                        { typeName: 'Reels', typeIcon: reelsTypeIcon, isSelected: false },
                        { typeName: 'Stories', typeIcon: storiesTypeIcon, isSelected: false },
                    ]
                },
            ],
            postTypeSelected: false,
            pageName: 'mainPage',
            aboutSelectValue: '',
            whatAboutContent: '',
            authToken: '',
            userId: '',
            campaignData: [],
            searchValue: '',
            selectedCampaignId: '',
            name: '',
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        let authToken = await getStorageData('authToken')
        let userId = await getStorageData('userId')
        this.setState({ authToken: authToken, userId: userId });

        this.handleGetCampaigns();
    }

    async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        )

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        runEngine.debugLog("API Message Recived", message);

        if(apiRequestCallId === this.getCampaignDataApiCallId){
            if(responseJson && responseJson.data){
                this.setState({
                    campaignData: responseJson.data,
                })
            }
        } else if(apiRequestCallId === this.generatePostApiCallId){
            if(responseJson && responseJson.data){
                const message = new Message(getName(MessageEnum.NavigationMessage));
                message.addData(getName(MessageEnum.NavigationTargetMessage), "CampaignsResults");
                message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
                this.send(message);
            }
        }
    }

    handleSetPage = (page: string) => {
        this.setState({ pageName: page })
    }

    handleToggle = (id: number): void => {
        this.setState((prevState) => ({
            socialMediaData: prevState.socialMediaData.map((item) =>
                item.id === id ? { ...item, connected: !item.connected } : item
            ),
        }));
    };

    handleTogglePostType = (platformId: number, typeName: string): void => {
        this.setState((prevState) => ({
            postTypeData: prevState.postTypeData.map((platform) =>
                platform.id === platformId
                    ? {
                        ...platform,
                        types: platform.types.map((type) =>
                            type.typeName === typeName
                                ? { ...type, isSelected: !type.isSelected }
                                : type
                        ),
                    }
                    : platform
            ),
        }), () => {
            this.state.postTypeData.forEach((element) => {
                if(element.types.some((type) => type.isSelected)){
                    this.setState(({
                        postTypeSelected: true,
                    }))
                }else{
                    this.setState(({
                        postTypeSelected: false,
                    }))
                }
            })
        });
    };

    handleAboutSelectChange = (value: string) => {
        this.setState({
            aboutSelectValue: value
        })
    }

    handleWhatAboutContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {value} = event.target;

        this.setState({
            whatAboutContent: value
        });
    }

    handleSearchChange = (text: string) => {
        this.setState({
            searchValue: text
        }, ()=> {
            this.handleGetCampaigns();
        })
    }

    formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    handleGetCampaigns = () => {
        const header = {
            "Content-Type": configJSON.getUserListApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCampaignDataApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.searchValue
            ? `${configJSON.getCampaignName}/${this.state.userId}/campaigns/search?query=${this.state.searchValue}`
            : `${configJSON.getCampaignName}/${this.state.userId}/campaigns`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.fetchFormDataMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleCreatePost = () => {
        const body = {
            "post": {
                "campaign_id": this.state.selectedCampaignId,
                "social_media_platform_name": "instagram",
                "post_type": "post",
                "generate_media": this.state.aboutSelectValue === 'Yes, generate images and/or video' ? true : false,
                "content": this.state.whatAboutContent,
            }
        }

        const header = {
            "Content-Type": configJSON.getUserListApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.generatePostApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCampaignName}/${this.state.userId}/posts`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postCampaignMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSelectCampaignId = (campaignId: string, name: string) => {
        this.setState({
            selectedCampaignId: campaignId,
            name: name,
        })
    }

    backToGenerateContent = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "GenerateContent");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
}

// Customizable Area End