import React from 'react';

import {

  Typography,
  Box,
  Grid,

} from "@mui/material";

const comtwitter = require("./commontwitterlogo.svg").default
const cominsta = require("./commoninsta.svg").default
const comface = require("./commonfacebook.svg").default
const comtitok = require("./commontiktok.svg").default
const comaitool = require("./commmonaitoolbar.svg").default

import { styled } from '@mui/material/styles';
interface footerProps {
  isActive: boolean
}

const Footerbar: React.FC<footerProps> = ({ isActive }) => {


  return (
    <Box>
      <StyleFooter isActive={isActive} >
        <Grid item xs={4}  style={{ flexDirection: 'column' }}>
          <Typography style={{ fontFamily: 'Open Sans', fontSize: '18px', fontWeight: 600, color: '#000000' }} >
            DatAI &#169; 2020-2024
          </Typography>
          <Box>
            <Box style={{ display: 'flex' }} width={'max-content'}>
              <Typography style={{  fontFamily: 'Open Sans', fontWeight: 400, fontSize: '14px', color: '#000000' }}>
                Privacy policy
              </Typography>
              <Typography style={{ fontFamily: 'Open Sans', fontWeight: 400, fontSize: '14px', color: '#000000' }}>
                Terms & Conditions
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '200px' }}  >
          <img src={comtwitter} alt="twitterogo" style={{ alignItems: 'center' }} />
          <img src={comface} alt="faceboologo" style={{ alignItems: 'center' }} />
          <img src={cominsta} alt="instagramlogo" style={{ alignItems: 'center' }} />
          <img src={comtitok} alt="tiktoklogo" style={{ alignItems: 'center' }} />


        </Box>
        <Box>
          <img src={comaitool} alt="aibottumimage" />
        </Box>
      </StyleFooter>
    </Box>
  );
};

export default Footerbar;


const StyleFooter = styled(Grid)<{ isActive: boolean }>(({ isActive }) => ({
  display: 'flex', alignItems: "center",
  justifyContent: 'space-between', position:'static',bottom:0,width:'100%', backgroundColor: isActive ? 'transparent' : '#F0EEEE', padding: '18px 15px',
  '@media (max-width: 1214px)': {
    display: 'flex', alignItems: "center", padding: '18px 15px',
    justifyContent:'space-between', position:'static',bottom:0,width:'100%',
  },
  '@media (max-width: 1174px)': {
    display: 'flex', alignItems: "center",
    justifyContent: 'space-between', padding: '18px 15px', position:'static',bottom:0,width:'100%',
  },
  '@media (max-width: 1154px)': {
    display: 'flex', alignItems: "center",
    padding: '18px 15px', justifyContent: 'space-between', position:'static',bottom:0,width:'100%',
  },
  '@media (max-width: 1124px)': {
    display: 'flex', alignItems: "center", padding: '18px 15px',
    justifyContent: 'space-between', position:'static',bottom:0,width:'100%',
  },
  '@media (max-width: 1074px)': {
    display: 'flex', alignItems: "center", position:'static',bottom:0,width:'100%',
    justifyContent: 'space-between', padding: '18px 15px',
  },
  '@media (max-width: 1024px)': {
    display: 'flex', alignItems: "center", padding: '18px 15px',
    justifyContent: 'space-between', position:'static',bottom:0,width:'100%',
  },
  '@media (max-width: 974px)': {
    display: 'flex', alignItems: "center",
    padding: '18px 15px', justifyContent: 'space-between', position: 'static',
  },
  '@media (max-width: 918px)': {
    display: 'flex', alignItems: "center", padding: '18px 0px',
    justifyContent: 'space-around', position: 'static',
  }, '@media (max-width: 858px)': {
    display: 'flex', alignItems: "start", gap: '16px',
    justifyContent: 'flex', position: 'static',
    flexDirection: 'column', padding: '24px'
  }, '@media (max-width: 768px)': {
    display: 'flex', alignItems: "start",
    justifyContent: 'center', gap: '16px',
    flexDirection: 'column', padding: '24px'
  },
  '@media (max-width: 626px)': {
    display: 'flex', alignItems: "start",
    justifyContent: 'center', padding: '24px',
    flexDirection: 'column'
  },
  '@media (max-width: 468px)': {
    display: 'flex', alignItems: "start", padding: '24px',
    justifyContent: 'center',
    flexDirection: 'column',
  }, '@media (max-width: 386px)': {
    display: 'flex', alignItems: "start",
    justifyContent: 'center', padding: '24px',
    flexDirection: 'column'
  },
}));

