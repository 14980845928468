import React from 'react';
import { Box, Typography, Button } from '@mui/material'
import { styled } from '@mui/material/styles';
import { regenerateIcon, saveToLibIcon, fullScreenIcon, deleteIcon, galleryIcon, paintIcon } from '../assets';
import { Image } from '../ContentManagementEditController';

interface Props {
    onRegenerate: (id: number) => void;
    onSaveToLibrary: (id: number) => void;
    onFullSize: (id: number) => void;
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
    onChooseFromGallery: (id: number) => void;
    image: Image;
}
const ImageGenerator: React.FC<Props> = ({ image, onRegenerate, onSaveToLibrary, onFullSize, onDelete, onEdit, onChooseFromGallery }) => {
    return (
        <StyledWrapper>
            <img src={image.image_url} style={styles.mainImage} />
            <Box sx={styles.btns}>
                <StyledSMButtonContainer>
                    <button style={styles.buttonStyle} onClick={() => onRegenerate(image.id)} data-testid="btn-regenerate">
                        <img src={regenerateIcon} alt="" />
                        <Typography sx={styles.f12}>Regenerate</Typography>
                    </button>
                    <button style={{ ...styles.buttonStyle, display: "none", }} onClick={() => onSaveToLibrary(image.id)} data-testid="btn-saveToLib">
                        <img src={saveToLibIcon} alt="" />
                        <Typography sx={styles.f12}>Save to library</Typography>
                    </button>
                    <button style={{ ...styles.buttonStyle, display: "none", }} onClick={() => onFullSize(image.id)} data-testid="btn-fullImage">
                        <img src={fullScreenIcon} alt="" />
                        <Typography sx={styles.f12}>Full image</Typography>
                    </button>
                    <button style={styles.buttonStyle} onClick={() => onDelete(image.id)} data-testid="btn-delete">
                        <img src={deleteIcon} alt="" />
                        <Typography sx={styles.f12}>Delete from carousel</Typography>
                    </button>
                </StyledSMButtonContainer>
                <StylesLGButtonContainer>
                    <Button sx={styles.editBtn} fullWidth onClick={() => onEdit(image.id)} data-testid="btn-edit">
                        <img src={paintIcon} alt=""  />
                        Edit this image
                    </Button>
                    <button style={styles.galleryBtn} onClick={() => onChooseFromGallery(image.id)} data-testid="btn-chooseFromLib">
                        <img src={galleryIcon} alt="" />
                        <Typography sx={styles.galleryBtnTxt}>Choose from library</Typography>
                    </button>
                </StylesLGButtonContainer>
            </Box>
        </StyledWrapper>
    );
}

export default ImageGenerator;

const StyledWrapper = styled(Box)({
    maxWidth: '688px',
    display: 'flex',
    gap: '16px',
    justifyContent: 'start',
    '@media (max-width: 888px)': {
        flexDirection: 'column'
    }
})

const StyledSMButtonContainer = styled(Box)({
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    flexGrow: 1,
    '@media (max-width: 888px)': {
    }
})

const StylesLGButtonContainer = styled(Box)({
    marginTop: '14px',
    display: 'flex',
    gap: '24px',
    flexGrow: 1,
    '@media (max-width: 888px)': {
        flexDirection: 'column',
        flexGrow: 0
    }
})

const styles = {
    mainImage: {
        height: '192px',
        width: '200px',
        borderRadius: '20px',
    },
    buttonStyle: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        backgroundColor: '#fff',
        color: '#fff',
        outline: 'none',
        border: 'none',
        fontFamily: 'Open sans',
    },
    f12: {
        fontSize: '12px',
        color: '#000',
        fontWeight: 400,
        lineHeight: '24px',
        fontFamily: 'Open sans',
    },
    btns: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1
    },
    editBtn: {
        background: "#ffffff",
        textTransform: "none",
        color: 'black',
        border: "2px solid black",
        fontFamily: "Open Sans",
        fontWeight: 600,
        fontSize: "16px",
        borderRadius: "8px",
        height:'36px',
        "&:hover": {
            background: "#ffffff"
        },
        maxWidth:"218px"
    },

    galleryBtn: {
        display: 'none',
        gap: '8px',
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000',
        borderRadius: '8px',
        border: '1px solid #000',
        flexGrow: 1,
        maxWidth: "218px",
    },
    editBtnTxt: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#000',
        fontFamily: 'Open sans',
    },
    galleryBtnTxt: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#fff',
        fontFamily: 'Open sans',
        lineHeight: '24px'
    }
}