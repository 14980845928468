// Customizable Area 
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities"
import { IBlock } from "framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");


export interface ApiData{
  contentType: string;
  method: string;
  endPoint: string; 
  body?: {}
}

export interface socialMediaPlatform {
  id: number;
  name: string;
  account_id: number;
  created_at: string;
  updated_at: string;
}

interface Caption {
  id: number;
  post_id: number;
  content: string;
  selected: boolean;
  created_at: string;
  updated_at: string;
}

export interface Image {
  id: number
  image_url: string
  filename: string
  content_type: string
  byte_size: number
}

export interface Video {
  id: number,
  video_url: string,
  filename:string,
  content_type: string,
  byte_size: number,
}

export interface Post{
  id: string;
  type: string;
  attributes: {
    id: number;
    content: string;
    post_type: string;
    draft: boolean;
    created_at: string;
    updated_at: string;
    social_media_platform: socialMediaPlatform;
    captions: Caption[];
    images: Image[];
    videos: Video[];
  }
}

export interface postsResponse{
  data: Post[]
}

export interface SocialMediaPostsResult {
  [platformName: string]: {
    count: number;
    posts: Post[];
  };
}

export interface Props{
  navigation: any;
}

interface S {
  render: string;
  messageType: string;
  succMessage: string;
  posts: Post[];
  postsByPlateforms: SocialMediaPostsResult;
  viewAllFor: string;
  totalPosts: number;
}

interface SS {
}

// Customizable Area End

export default class CampaingnResultController extends BlockComponent<Props, S, SS>{
  // Customizable Area Start
    postsAPIId: string = "";
    postDeleteAPIId: string = "";
    campaignId: string = "";
    authToken: string = "";
    userId: string = "";
    campaignName:string = "";
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.NavigationMessage),
        getName(MessageEnum.NavigationTargetMessage),
        getName(MessageEnum.NavigationPropsMessage),
      ];
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      this.state = {
        render: 'results',
        messageType: '',
        succMessage: "",
        posts: [],
        postsByPlateforms: {},
        viewAllFor: "",
        totalPosts: 0,
      };
    }

  async componentDidMount() {
    const url = window.location.href;
    const params = new URLSearchParams(new URL(url).search);
    const id = params.get('id');
    const name = params.get('name');
    this.campaignId = id || "";
    this.campaignName = name || "";

    this.authToken = await getStorageData('authToken');
    this.userId = await getStorageData('userId');
    this.getPosts();
  }

  apiCall = (data: ApiData) => {
    const { contentType, method, endPoint } = data;
    const token =  this.authToken;
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.postsAPIId){
        this.handlePostsResponse(responseJson);
      }
      if(apiRequestCallId === this.postDeleteAPIId){
        this.handleDeleteResponse(responseJson);
      }
    } 
  }

  getPosts = () => {
    this.postsAPIId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method: "GET",
      endPoint: `bx_block_campaigns/accounts/${this.userId}/campaigns/${this.campaignId}/posts`
    })
  }

  countSocialMediaPosts(data: Post[]): SocialMediaPostsResult {
    return data.reduce((acc: Record<string, { count: number; posts: Post[] }>, daum: Post) => {
      const platformName = daum.attributes.social_media_platform.name;
  
      if (acc[platformName]) {
        acc[platformName].count++;
        acc[platformName].posts.push(daum);
      } else {
        acc[platformName] = {
          count: 1,
          posts: [daum]
        };
      }

      this.setState((prevState) => ({
        totalPosts: prevState.totalPosts + 1
      }));

      return acc;
    }, {});
  }

  handlePostsResponse = (response: postsResponse) => {
    this.setState({posts:response.data, totalPosts: 0})
    this.setState({postsByPlateforms:this.countSocialMediaPosts(response.data)});
  }

  handleDeleteResponse = (response: any) => {
    this.getPosts();
  }

  handleDeletePost = (id:string) => {
    if(this.state.viewAllFor != "" && this.state.postsByPlateforms[this.state.viewAllFor]['posts'].length === 1){
      this.setState({viewAllFor:'', render:'results'});
    }
    this.postDeleteAPIId = this.apiCall({
      contentType: configJSON.ApiContentTypeJson,
      method:'DELETE',
      endPoint: `bx_block_campaigns/accounts/${this.campaignId}/posts/${id}`
    })
  }

  handleRenamePost = (name: string, id: string) => {

  }

  handleEditPost = (id: string) => {
    this.props.navigation.navigate("EditContent",{id});
  }

  goToAddContentPage = () => {
    this.props.navigation.setParams({"id": this.campaignId});
    this.props.navigation.navigate("CampaignAddContent", {id:this.campaignId, name: this.campaignName});
    }
  
  navigateBackToCampaignPage = () => 
    this.props.navigation.navigate("Campaigns")

  // Customizable Area End
}