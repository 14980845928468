// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    InputAdornment,
    TextField,Select,
    MenuItem
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { productIcon, subcriberIcon, blogIcon, postIcon, backIcon } from './assets';
import { styled } from "@mui/styles";
import GuidedWalkthroughController, {
    Props,
    configJSON
} from "./GuidedWalkthroughController.web";
import {
    Checkbox
} from "@mui/material";
import BreadcrumbNavigationPage from '../../breadcrumbnavigation/src/BreadcrumbNavigationPage.web';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';

export default class GuidedWalkthrough extends GuidedWalkthroughController {
    constructor(props: Props) {
        super(props);

    }

    topHTML = (backPage: string, title: string) => {
        return <>
            <img src={backIcon} alt="" data-test-id='btnBackFromPostForSocial' onClick={ this.handleSetPage.bind(this,backPage)} />
            <Box className='postTextBox'>
                <img src={postIcon} alt="" />
                <Typography className="postText">{configJSON.postForSocialMediaText}</Typography>
            </Box>
            <Typography className="postMainTitle">{title}</Typography>
        </>
    }

    nextButton = (path: string, dataTestId ?:string) => {  
        return <Box className='nextBtnBox'>
            <button className='nextBtn' data-test-id={dataTestId || "nextButtontestId"} onClick={ this.handleSetPage.bind(this,path)}>{configJSON.nextBtn}</button>
        </Box>
    }

    mainPage = () => {
        return <>
            <ContentHeading data-testid='mainPage'>{configJSON.usefulContent}</ContentHeading>
            <StartPointContainer>
                <StartPointBox data-test-id='btnOpenPostForSocial' onClick={this.handleSetPage.bind(this,'platForm')}>
                    <img src={productIcon} className="start_img" />
                    <Typography className="start_point_content">{configJSON.productContent}</Typography>
                </StartPointBox>
                <StartPointBox>
                    <img src={blogIcon} className="start_img" />
                    <Typography className="start_point_content">{configJSON.campaignContent}</Typography>
                </StartPointBox>
                <StartPointBox>
                    <img src={subcriberIcon} className="start_img" />
                    <Typography className="start_point_content">{configJSON.subcriberContent}</Typography>
                </StartPointBox>

            </StartPointContainer>
            <GuideContainer>
                <GuideButton data-testid="nextBtnMainPage" data-test-id='backToGenerateContent' onClick={this.backToGenerateContent}>
                    {configJSON.GuideBtnContent}
                </GuideButton>
            </GuideContainer>
        </>
    }

    platformPage = () => {
        return <Box  data-testid="platformPage">
            {this.topHTML('mainPage', configJSON.postMainTitle)}
            <Typography className="multiSelectOptionText">{configJSON.youCanSelectText}</Typography>
            <Box className='socialIconBox'>
                {this.state.socialMediaData.map((platform) => (
                    <Box
                        key={platform.id}
                        data-test-id='handleToggle'
                        onClick={() => this.handleToggle(platform.id)}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "16px 16px 16px 16px",
                            border: `2px solid ${platform.connected ? "#B3B3B3" : "#DDDDDDDD"}`,
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                    >
                        <Checkbox
                            checked={platform.connected}
                            style={{
                                color: "#3D3D3D",
                                padding: 0,
                                marginRight: "18px",
                            }}
                        />
                        <img
                            src={platform.icon}
                            alt={platform.name}
                        />
                        <Typography className='platformName'>{platform.name}</Typography>
                    </Box>
                ))}
            </Box>
            {this.state.socialMediaData[0].connected && this.nextButton('postType')}
        </Box>
    }

    postType = () => {
        return <>
            {this.topHTML('platForm', configJSON.whichPostDoYouWish)}
            {
                this.state.postTypeData && this.state.postTypeData.map((data) => {
                    return <Box data-testid="postType" key={data.id}>
                        <Box className='typeSocialBox'>
                            <img src={data.icon} alt={data.name} />
                            <Typography className='typeSocialBoxText' style={{ color: data.color }}>{data.name}</Typography>
                        </Box>
                        <Box className='typeSocialListBox'>
                            {
                                data.types.map((type) => {
                                    return <Box
                                        key={type.typeName}
                                        className='typeSocialListSingleBox'
                                        data-test-id='handleTogglePostType'
                                        onClick={() => this.handleTogglePostType(data.id, type.typeName)}
                                        style={{ backgroundColor: type.isSelected ? '#FFFDD8' : '#FFFFFF' }}
                                    >
                                        <img src={type.typeIcon} alt={type.typeName} />
                                        <Typography className='typeSocialListName'>{type.typeName}</Typography>
                                    </Box>
                                })
                            }
                        </Box>
                    </Box>
                })
            }
            {this.state.postTypeSelected && this.nextButton('postAbout', 'postTypeToNext')}
        </>
    }

    postAbout = () => {
        return <>
            {this.topHTML('postType', configJSON.whatArethesePostAbout)}
            <Box>
                <textarea
                    placeholder="Please describe"
                    className="whatAboutInput"
                    data-test-id="whatAboutInput"
                    rows={5}
                    value={this.state.whatAboutContent}
                    onChange={this.handleWhatAboutContent}
                />
                <Typography className="whatAboutSTitle">{configJSON.whatAboutSelectTitle}</Typography>

                <Select
                    className="whatAboutSelect" 
                    data-test-id='whatAboutSelect' 
                    onChange={(event) => this.handleAboutSelectChange(event.target.value)}
                    labelId="content-user"
                    placeholder="Select"
                    IconComponent= {KeyboardArrowDownTwoToneIcon}
                    value={this.state.aboutSelectValue}
                    displayEmpty
                    renderValue={(selected:string) => {
                        if (selected === "") {
                        return <Box className="place_text">{"Select"}</Box>;
                        }
                        return selected;
                    }}
                    MenuProps={{
                        PaperProps: {
                        sx: {
                            backgroundColor: "#F9F9F9 !important",
                            "& .Mui-selected": {
                                backgroundColor:"#F9F9F9 !important"
                            }
                        },
                        },
                    }}
                >
                    <MenuItem className="content_Item" value={'Yes, generate images and/or video'}>{'Yes, generate images and/or video'}</MenuItem>
                    <MenuItem className="content_Item" value={'No, I’m using my content'}>{'No, I’m using my content'}</MenuItem>
                </Select>
            </Box>

            {this.state.aboutSelectValue && this.state.whatAboutContent && this.nextButton('postsubAbout', 'postSubAboutNext')}
        </>
    }
    
    postsubAbout = () => { 
        return <>
            {this.topHTML('postAbout', configJSON.whatArethesePostsubAbout)}
                  <input
                    placeholder="Enter the campaign name"
                    className="whatAboutsub"
                    value={this.state.name}
                />
                <ContentHeading>{configJSON.newcamping}</ContentHeading>
                <StyledSearchTextField
                data-test-id='searchInput'
                placeholder="Search"
                fullWidth
                value={this.state.searchValue}
                    onChange={(event) => this.handleSearchChange(event.target.value)}
                InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            />
            <Box display="flex" justifyContent="space-between" sx={{marginTop:'36px', columnGap: '15px'}}>
                {this.state.campaignData && this.state.campaignData.map((item, index) => {
                    return index <= 2 && <StyledCampaignCard data-test-id='handleSelectCampaignId' onClick={() => this.handleSelectCampaignId(item.id, item.attributes.name)}>
                        <Box sx={{ display: "flex", width: "100%", height: "100%", flexDirection: "column", gap: "12px", justifyContent: "space-between" }}>
                            <Box sx={{ display: 'flex', width: "100%", justifyContent: "space-between", alignItems: "center", position: "relative" }}>
                                <Typography sx={{ fontSize: "12px", fontFamily: "Open Sans", fontWeight: 400, borderRadius: "100px", background: "#FFFBAA", color: "#4D4D4D", padding: "2px 8px" }}>
                                    {`Created on: ${this.formatDate(item.attributes.created_at)}`}
                                </Typography>
                                
                            </Box>
                            <Box sx={{ flexGrow: 1, overflowY: "auto", fontSize: "24px", fontFamily: "Open Sans", fontWeight: 600, color: "#000000", padding: "2px" }}>
                                {item.attributes.name}
                            </Box>
                        </Box>
                    </StyledCampaignCard>
                })}
            </Box>
            {
                this.state.selectedCampaignId && <Box className='nextBtnBox'>
                    <button className='nextBtn' data-test-id="handleCreatePost" onClick={this.handleCreatePost}>{configJSON.nextBtn}</button>
                </Box>
            }
        </>
    }

    checkPage = () => {
        switch (this.state.pageName) {
            case 'mainPage': return this.mainPage();

            case 'platForm': return this.platformPage();

            case 'postType': return this.postType();

            case 'postAbout': return this.postAbout();

            case 'campaign': return 'compaign';
            
            case 'postsubAbout': return this.postsubAbout();

            default: return true;
        }
    }

    render() {
        return (
            <BreadcrumbNavigationPage navigation={this.props.navigation}>
                <MainContainer style={{ backgroundColor: this.state.pageName !== 'mainPage' ? '#fff' : "#FFFDD8", }}>
                    <GenerateBox>
                        {this.checkPage()}
                    </GenerateBox>
                </MainContainer>
            </BreadcrumbNavigationPage>
        );
    }
}

const MainContainer = styled(Box)({
    height: "100%",
    padding: "100px",
    overflow: "scroll",
    "@media (max-width: 1000px)": {
        padding: "50px 20px"
    },
    "@media (max-width: 600px)": {
        padding: "50px 20px"
    },
})
const SelectBox = styled(Box)({
    marginTop: "40px",
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    color: '#4D4D4D',
    "& .content_select" : {
     width : "100%",
     height: "50px",
     borderRadius: "8px",
     border: "1px solid #DDDDDDDD",
     "&:hover":{
        border : "none"
     },
     "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":{
        backgroundColor: "#F9F9F9 !important",
        color:"#4D4D4D !important"
     },
     "&.css-1d3z3hw-MuiOutlinedInput-notchedOutline" : {
        borderStyle : "none"
     },
     "& .css-6hp17o-MuiList-root-MuiMenu-list":{
        backgroundColor: "#F9F9F9 !important"
     },
     "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected":{
        backgroundColor: "#D9D9D9 !imortant"
     },
     "& .MuiSelect-icon":{
        color: "#000000"
     },
    "& .content_Item" : {
        "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root" : {
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: 400,
            color: '#4D4D4D',
         },
         "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected":{
            backgroundColor: "#D9D9D9 !imortant"
         }
    }
  }
 })
const ContentHeading = styled(Box)({
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "18px",
    color: "#4D4D4D",
    marginTop: "80px"
})
const StartPointContainer = styled(Box)({
    display: "flex",
    marginTop: "30px",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "20px"
})
const StartPointBox = styled(Box)({
    flex: "1 0 150px",
    border: "0.5px solid #000000",
    borderRadius: "20px",
    padding: "16px",
    textAlign: "center",
    backgroundColor: "#FFFEE1",
    cursor: "pointer",
    "& .start_img": {
        height: "24px",
        color: "#3D3D3D"
    },
    "& .start_point_content": {
        marginTop: "15px",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontSize: "16px",
        color: "#4D4D4D",
    }
})
const GuideContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "45px",
    gap: "20px"
})
const GuideButton = styled(Box)({
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "16px",
    color: "#FFFFFF",
    backgroundColor: "#000000",
    padding: "10px 36px",
    borderRadius: "8px",
    cursor: "pointer",
    marginLeft: 'auto',
    width: '210px',
    textAlign: 'center'
})
const GenerateBox = styled(Box)({
    width: "100%",
    "& .postTextBox": {
        width: 'fit-content',
        backgroundColor: '#FFFDD8',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '24px 0 32px 0',
        padding: '6px 14px 6px 14px',
        borderRadius: '100px',
    },
    "& .postText": {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 400,
        color: '#4D4D4D',
        marginLeft: '4px',
    },
    '& .postMainTitle': {
        fontFamily: 'Open Sans',
        fontSize: '24px',
        fontWeight: 600,
        color: '#4D4D4D',
    },
    '& .multiSelectOptionText': {
        color: '#4D4D4D',
        fontFamily: 'Open Sans',
        fontSize: '18px',
        fontWeight: 400,
        marginTop: '32px',
    },
    '& .socialIconBox': {
        width: '100%',
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: "10px",
        margin: '32px 0 0 0',
    },
    '& .platformName': {
        marginLeft: '8px',
        fontFamily: 'Open Sans',
        fontSize: '18px',
        fontWeight: 400,
        color: '#4D4D4D',
    },
    '& .nextBtnBox': {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '32px',
    },
    '& .nextBtn': {
        border: 'none',
        width: 'fit-content',
        padding: '12px 86.5px 12px 86.5px',
        borderRadius: '8px',
        backgroundColor: '#000000',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#FFFFFF',
    },
    '& .typeSocialBox': {
        width: 'fit-content',
        marginTop: '32px',
        display: 'flex',
        alignItems: 'center',
    },
    '& .typeSocialBoxText': {
        fontFamily: 'Open Sans',
        fontSize: '18px',
        fontWeight: 400,
        marginLeft: '8px',
    },
    '& .typeSocialListBox': {
        display: 'flex',
        alignItems: 'center',
        marginTop: '24px',
        columnGap: '32px',
    },
    '& .typeSocialListSingleBox': {
        width: '160px',
        height: '100px',
        border: '1px solid #DDDDDDDD',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: '8px',
        cursor: 'pointer'
    },
    '& .typeSocialListName': {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 400,
        marginTop: '8px',
        color: '#4D4D4D',
    },
    '& .whatAboutInput': {
        resize: 'none',
        height: '133px',
        width: '100%',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        borderRadius: '8px',
        border: '1px solid #DDDDDD',
        marginTop: '32px',
        padding: '4px 4px 4px 4px',
    },
    '& .whatAboutSTitle': {
        fontFamily: 'Open Sans',
        fontSize: '18px',
        fontWeight: 400,
        color: '#4D4D4D',
        marginTop: '32px',
    },
    '& .whatAboutSelect': {
        width: '100%',
        height: '48px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#B3B3B3',
        border: '1px solid #DDDDDDDD',
        padding: '0 12px 0 8px',
        marginTop: '24px',
    },
    '& .whatAboutsub': {
        width: '100%',
        height: '48px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#4D4D4D',
        border: '1px solid #DDDDDDDD',
        padding: '0 12px 0 8px',
        marginTop: '24px',
    },
    "& .content_Item" : {
        "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root" : {
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: 400,
            color: '#4D4D4D',
         },
         "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected":{
            backgroundColor: "#D9D9D9 !imortant"
         }
    },
})
                
const StyledSearchTextField = styled(TextField) ({
    width:"calc(100% - 234px)",
   '& .MuiOutlinedInput-root': {
    fontFamily: "Open Sans",
     height: "48px",
     background:"white",
     '& fieldset': {
       border:"1px solid #DDDDDD",
       borderRadius:"100px",
     },
     '&.Mui-focused fieldset': {
       border:"1px solid #000000",
     },
     '&:hover fieldset': {
       borderColor: '#797979',
     },
     '&.Mui-focused:hover fieldset': {
       borderColor: '#000000'
     }, 
     '&.MuiOutlinedInput-root' :{
        marginTop:"58px",
     }
   },
   '& .MuiInputBase-input': {
     height: '100%',
     width:"100%" 
   },
   '@media (max-width: 854px)': {
     width:"calc(100% - 170px)"
   },
   '@media (max-width: 754px)': {
     width:"100%"
   },
   '@media (max-width: 600px)': {
     width:"calc(100% - 170px)"
    },
    '@media (max-width: 463px)': {
     width:"100%"
    },
 });
 
const StyledCampaignCard = styled(Box)({
    width: "260px",
    height: '106px',
    border: "1px solid #DDDDDD",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    background: "#FFFDD8",
    borderRadius: "8px",
    padding: "24px 16px 24px 16px",
    gap: "36px",
    '&:hover': {
        background: "#FCF670",
        cursor: "pointer"
    },
    '@media (max-width: 363px)': {
        width: 'clamp(150px, 70vw, 260px)',
    }
})

// Customizable Area End
