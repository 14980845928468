// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { toast } from "react-toastify";
export const configJSON = require("./config");


export interface PrivacyAndPolicy {
  id: string;
  type: string;
  attributes: {
    description:string;
    id:number
  };
}
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isLoading: boolean;
  privacyPolicy:PrivacyAndPolicy |null;
}

interface SS {
  id: string;
}

export default class PrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  getPrivacyPolicyCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      isLoading: false,
      privacyPolicy:null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
     await this.getPrivacyPolicy()
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getPrivacyPolicyCallId) {
      if(responseJson.data){
        this.setState({
          privacyPolicy: responseJson.data,

        });
      }else{
        toast.error("Something went wrong!")
      }
    }
  }

  async getPrivacyPolicy(){
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrivacyPolicyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicyApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}

  // Customizable Area End