import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from 'react';
import { cardImage1, cardImage2, cardImage3, cardImage4, cardImage5, cardImage6, cardImage7 } from './assets';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: object;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    cardData: Array<{
        name: string;
        imageURl: string;
      }>;
    openImageMenuIndex: number | boolean;
    openVideoMenuIndex: number | boolean;
    selectedImage : string;
    openDeleteModal: boolean;
    openRenameModal: boolean;
    openUploadModal: boolean;
    renameText: string;
    selectedFile: File |null;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class PhotosLibraryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            cardData: [
                { name: "image name.png", imageURl: cardImage1 },
                { name: "image name.png", imageURl: cardImage2 },
                { name: "image name.png", imageURl: cardImage3 },
                { name: "image name.png", imageURl: cardImage4 },
                { name: "image name.png", imageURl: cardImage5 },
                { name: "image name.png", imageURl: cardImage6 },
                { name: "image name.png", imageURl: cardImage7 },
              ],
            openImageMenuIndex: false,
            openVideoMenuIndex: false,
            selectedImage: "",
            openDeleteModal: false,
            openRenameModal: false,
            openUploadModal: false,
            renameText: "",
            selectedFile: null, 
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleMenu = (index: number) => {
        if (typeof this.state.openImageMenuIndex === "number") {
          this.setState({ openImageMenuIndex: false });
        } else {
          this.setState({ openImageMenuIndex: index });
        }
      };
    
    handleVideoMenu = (index: number) => {
        if (typeof this.state.openVideoMenuIndex === "number") {
          this.setState({ openVideoMenuIndex: false });
        } else {
          this.setState({ openVideoMenuIndex: index });
        }
      };

    openImageModal = (imageURL: string) => {
        this.setState({ selectedImage: imageURL });
      };
    
    closeImageModal = () => {
        this.setState({ selectedImage: "" });
    };

    openDeleteModal = () => {
        this.setState({ openDeleteModal: true });
    };

    closeDeleteModal = () => {
        this.setState({ openDeleteModal: false });
    };

    openRenameModal = (itemName: string) => {
        this.setState({ openRenameModal: true, renameText: itemName });
    };

    closeRenameModal = () => {
        this.setState({ openRenameModal: false, renameText: "" });
    };

    openUploadModal = () => {
        this.setState({ openUploadModal: true });
    };

    closeUploadModal = () => {
        this.setState({ openUploadModal: false });
    };

    handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ renameText: event.target.value })
    };

    handleUploadChange = (file: File | null) => {
        if (file) {
            this.setState({ selectedFile: file })
        }
    }
    // Customizable Area End
}