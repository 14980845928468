import React from "react";
import { Box, IconButton} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/material/styles';


const ButtonGroup = ({ next, previous }: { next?: () => void; previous?: () => void }) => {
  return (
    <StyledButtonContainer>
        <IconButton
          onClick={previous}
          style={{
            background: "#868585a8",
            opacity: "0.3",
            border: "none",
            cursor: "pointer",
            fontSize: "24px",
            color:"black"
          }}
        >
          <KeyboardArrowLeftIcon style={{color:"black"}}/>
        </IconButton>
        <IconButton
          onClick={next}
          style={{
            background: "#868585a8",
            opacity: "0.3",
            border: "none",
            cursor: "pointer",
            fontSize: "24px",
            marginLeft:"15px"
          }}
        >
          <KeyboardArrowRightIcon sx={{color:"#000000"}}/>
        </IconButton>
    </StyledButtonContainer>
  );
};


export default ButtonGroup;


const StyledButtonContainer = styled(Box)({
    position: "absolute",
    width: "327px",
    bottom: "-8%",
    left:"calc(50% - 180px)",
    display: "flex",
    flex:1,
    justifyContent: "space-between",
    transform: "translateY(-50%)",
    '@media (max-height: 650px)':{
      justifyContent:'center',
    },
    '@media (max-width:853px)': {
      // left:-78,
      // width: "calc(100% + 156px)",
    },
    '@media (max-width:506px)': {
      // left:-49,
      // width: "calc(100% + 96px)",
    },
});