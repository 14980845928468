// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities"
import { toast } from "react-toastify";
import { ChangeEvent } from "react";
export interface Imagetype {
  uris: string;
  width: number;
  height: number;
}

export const configJSON = require("./config");


export interface Props {
  navigation: any;
}

interface S {
  loading: boolean;
  campaignName:string;
  campaigns:Campaigns[]
}

export interface Campaigns {
  id: string,
    type: string,
    attributes: {
      id: number
      name: string,
      created_at: string,
      updated_at: string
    },
    relationships: {
        account: {
            data: {
                id:string,
                type: string
            }
        }
    }
}
export interface CampaignsResponse {
  data:Campaigns[]
}
interface SS {
  idManagement: number | string;
}
// Customizable Area End
export default class ContentManagementCampaignsController extends BlockComponent<
  Props,
  S,
  SS
> {
// Customizable Area Start

  userDataListCall:string|Message=''
  addDataCall:string|Message=''
  getUserCampaignsApiCallId:string="";
  createCampaignsApiCallId:string="";
  deleteCampaignsApiCallId:string="";
  editCampaignsApiCallId:string="";
  searchCampaignsApiCallId:string="";
  authToken:string="";
  userId:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      campaignName:"Pet food 50% off campaign",
      campaigns:[],

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
// Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    this.authToken = await getStorageData('authToken');
    this.userId = await getStorageData('userId');
    this.getCampaigns()
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.getUserCampaignsApiCallId){
          this.handleGetCampaingsResponse(responseJson)
      }else if (apiRequestCallId === this.createCampaignsApiCallId){
        this.handleCreateCampaignResponse(responseJson)
      }else if(apiRequestCallId === this.deleteCampaignsApiCallId){
        this.handleDeleteCampaign(responseJson)
      }else if(apiRequestCallId === this.editCampaignsApiCallId){
        this.handleEditCampaignResponse(responseJson)
      }else if(apiRequestCallId === this.searchCampaignsApiCallId){
        this.handleSearchCampaignResponse(responseJson)
      }
    } 
    // Customizable Area End
  }

  // Customizable Area Start

  async getCampaigns(){
    const header = {
      "Content-Type": "application/json",
      token: this.authToken
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const endPoint = `${configJSON.getCampaignsEndPoint}${this.userId}/campaigns`
    this.getUserCampaignsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleGetCampaingsResponse(response:CampaignsResponse){
    if(response.data.length>0){
      this.setState({campaigns:response.data})
    }
  }
  async createCampaigns(){
    const header = {
      "Content-Type": "application/json",
      token: this.authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const endPoint = `${configJSON.getCampaignsEndPoint}${this.userId}/campaigns`
    this.createCampaignsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCreateCampaignResponse(response:{data?:Campaigns}){
    if(response.data){
      this.getCampaigns()
      toast.success("Campaign Created!")
    }else{
      toast.error("Something went wrong, Please try again later!")
    }
  }
  
  deleteCampaigns(id:string){
    const header = {
      "Content-Type": "application/json",
      token: this.authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const endPoint = `${configJSON.getCampaignsEndPoint}${this.userId}/campaigns/${id}`
    this.deleteCampaignsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDeleteCampaign(response:{success?:boolean}){
    if(response.success){
      this.getCampaigns()
      toast.success("Campaign deleted!")
    }else{
      toast.error("Something went wrong, Please try again later!")
    }
  }

  editCampaigns(id:string, newName:string){
    const header = {
      "Content-Type": "application/json",
      token: this.authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const endPoint = `${configJSON.getCampaignsEndPoint}${this.userId}/campaigns/${id}`
    const body ={
      campaign: {
          name:newName
      }
    }
    this.editCampaignsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleEditCampaignResponse(response:{data?:Campaigns}){
      if(response.data){
        this.getCampaigns()
        toast.success("Campaign updated!")
      }else{
        toast.error("Something went wrong, Please try again later!")
      }
  }

  handleSearch(event:ChangeEvent<HTMLInputElement>){
    if(event.target.value!==""){
      this.searchCampaigns(event.target.value)
    }else{
      this.getCampaigns()
    }
  }
  searchCampaigns(name:string){
    const header = {
      "Content-Type": "application/json",
      token: this.authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const endPoint = `${configJSON.getCampaignsEndPoint}${this.userId}/campaigns/search?query=${name}`
    this.searchCampaignsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSearchCampaignResponse(response:CampaignsResponse){
      if(response.data){
       this.setState({campaigns:response.data})
      }else{
        this.setState({campaigns:[]})
      }
  }

  handleCampaignClick(id:string, name:string){
    this.props.navigation.setParams({ "id": id, 'name': name });
    this.props.navigation.navigate("CampaignsResults", { id: id, name: name });
  }
  // Customizable Area End
}
