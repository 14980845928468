import React from "react";
import Carousel from "react-multi-carousel";
import { Box} from '@mui/material'
import ButtonGroup from "./ButtonGroup";
import { styled } from '@mui/material/styles';
interface Props {
  children: React.ReactNode;
}

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1255 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1254, min: 854 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 950, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};



const CarouselContainer: React.FC<Props> = ({ children }) => {

  return (
    <StyledOuterContainer>
      <Carousel
       draggable={true}
       showDots={true}
       responsive={responsive}
       arrows={false}
       renderDotsOutside
       renderButtonGroupOutside={true}
       removeArrowOnDeviceType={["mobile"]}
       renderArrowsWhenDisabled
       additionalTransfrom={0}
       customButtonGroup={<ButtonGroup />}
       swipeable
      >
        {children}
      </Carousel>
    </StyledOuterContainer>
  );
};

export default CarouselContainer;


 const StyledOuterContainer = styled(Box)({
  width:"1065px", position:"relative",  paddingBottom: "43px" ,
  marginRight:'-24px',
  '@media (max-width:1254px)': {
    width:"706px",
  },
  '@media (max-width:950px)': {
    width:"336px",
    marginRight:'0px',
  },
  '@media (max-width:449px)': {
    width:"295px",
  },
});
