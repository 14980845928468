// Customizable Area Start
import React from "react";

import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material'
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import {StyledFormControl,} from "../../../components/src/StyledComponents"
import InstagramPreview from "./components/InstagramPreview"
import ImageGenerator from "./components/ImageGenerator"
import BreadcrumbNavigationPage from "../../../blocks/breadcrumbnavigation/src/BreadcrumbNavigationPage.web"
import ContentManagementEditController, {
  Props,
} from "./ContentManagementEditController";
import { instagramColor } from "./assets";

export default class ContentManagementEdit extends ContentManagementEditController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <BreadcrumbNavigationPage navigation={this.props.navigation}>
        <Box sx={{display:"flex", flexDirection:"column", gap:"24px", padding:"32px",}}>
          <Box sx={webStyle.pageHeadingContainer}>
            <ArrowBackIcon onClick={this.props.navigation.goBack}/>
            <StyledBoostButton>Boost</StyledBoostButton>
            <Typography sx={{fontFamily:"Open Sans", fontWeight:600, fontSize:"18px"}}>{this.state.contentName}</Typography>
            <EditOutlinedIcon sx={{display:"none"}}/>
          </Box>
          <StyledContentContainer>
            <StyledContentPreviewContainer>
              <InstagramPreview  data-testid='postPreview'images={this.state.contentImages} caption={this.state.contentCaptions.length>0 ?this.state.contentCaptions[this.state.activeCaptionIndex].content:""}/>
            </StyledContentPreviewContainer>
            <StyledContentCustomizationContainer>
              <Box sx={{display:"flex", justifyContent:"space-between", alignItems:'center'}}>
                <Box sx={{display:'flex', width:"fit-content", gap:"16px"}}>
                  <img src={instagramColor} height={'20px'} width={'20px'}/>
                  <Typography sx={{color:"#7153B8"}}>Instagram</Typography>
                  <Typography
                    sx={{ 
                      textTransform: "capitalize",
                      background: "#FFFBAA",
                      borderRadius:"100px",
                      padding:"2px 8px 2px 8px",
                      fontSize:'14px',
                      fontFamily:"Open Sans",
                      color:"#4D4D4D",
                      fontWeight:400}}
                    >{this.state.contentDetails?.type}</Typography>
                </Box>
                <Box sx={{display:'flex', width:"fit-content", gap:"16px"}}>
                  <Typography sx={{fontSize:'14px', fontFamily:"Open Sans", fontWeight:400}}>Status:</Typography>
                  <Typography sx={{fontSize:'14px', fontFamily:"Open Sans", fontWeight:600}}>Draft</Typography>
                </Box>
              </Box>
              <Box sx={{display:"flex", flexDirection:"column", gap:"24px"}}>
                <Box sx={{display:'flex', gap:"8px" , alignItems:"center"}}>
                  <NavigateBeforeIcon onClick={this.goToPreviousCaption.bind(this)} data-testid='previousContentButton'/>
                  <Typography sx={{fontFamily:"Open Sans",  fontWeight:600, fontSize:"18px", textAlign:"center"}}>
                    {`Caption ${this.state.activeCaptionIndex+1}/${this.state.contentCaptions.length}`}
                  </Typography>
                  <NavigateNextIcon onClick={this.goToNextCaption.bind(this)} data-testid='nextContentButton'/>
                </Box>
                <StyledFormControl isRightAlignText>
                  <TextField
                    helperText={"Max 300 characters"}
                    multiline
                    data-testid='contentCaptionInput'
                    rows={4}
                    value={this.state.contentCaptions.length>0 ?this.state.contentCaptions[this.state.activeCaptionIndex].content:""}
                    disabled={!this.state.isEditContent}
                    onChange={this.handleChangeCaption.bind(this)}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        '& .MuiInputBase-input': {
                          fontFamily:"Open Sans",
                          fontWeight:400,
                          fontSize:'14px',
                          color:"#4D4D4D",
                        },
                        '& fieldset': {
                          borderColor: '',
                          border: "1px solid #DDDDDDD"
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                          background:"transparent"
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#EFEFEF',
                        },
                  
                      },
                    }}
                  />
                </StyledFormControl>
                <StyledGenerateEditButtonContainer>
                  <Button
                    data-testid='editManuallyButton'
                    startIcon={this.state.isEditContent? <SaveIcon/> : <EditOutlinedIcon/>}
                    variant="contained"
                    fullWidth
                    onClick={ this.state.isEditContent ? this.saveManuallyWrittenCaption.bind(this): this.handleEditManually.bind(this)}
                    sx={{
                      background:"#000000",
                      textTransform:"none",
                      fontFamily:"Open Sans",
                      fontWeight:600,
                      fontSize:"16px",
                      borderRadius:"8px",
                      "&:hover":{
                        background:"#000000"
                      }
                    }}
                  >
                    {this.state.isEditContent? "Save" : "Edit manually"}
                  </Button>
                  <Button
                    data-testid='regenerateContentButton'
                    startIcon={this.state.isRegeneratingCaption? <CircularProgress sx={{color:'black', marginRight:1.5}} size={20}/>:<RefreshIcon  style={{ fontSize:27 }}/>}
                    fullWidth
                    onClick={this.regenerateCaption.bind(this)}
                    disabled={this.state.isRegeneratingCaption}
                    sx={{
                      background:"#ffffff",
                      textTransform:"none",
                      color:'black',
                      border:"2px solid black",
                      fontFamily:"Open Sans",
                      fontWeight:600,
                      fontSize:"16px",
                      borderRadius:"8px",
                      "&:hover":{
                        background:"#ffffff"
                      }
                    }}
                  >
                    Regenerate
                  </Button>
                </StyledGenerateEditButtonContainer>
                <StyledGenerateEditButtonContainer>
                  <Typography sx={{fontFamily:"Open Sans", fontSize:"18px", fontWeight:600, color:"#4D4D4D"}}>
                    Images
                  </Typography>
                  <Button
                    startIcon={<EditOutlinedIcon/>}
                    variant="contained"
                    sx={{
                      background:"#000000",
                      textTransform:"none",
                      fontFamily:"Open Sans",
                      fontWeight:600,
                      fontSize:"16px",
                      borderRadius:"8px",
                      "&:hover":{
                        background:"#000000"
                      },
                      display:"none",
                    }}
                  >
                    Add image
                  </Button>
                </StyledGenerateEditButtonContainer>
              </Box>
              {this.state.contentImages.map((image, index)=>(
                <ImageGenerator key={index} data-testid={`imageGenerator${index}`} image={image} onRegenerate={this.regenerateImage.bind(this)} onSaveToLibrary={(id)=>{}}  onFullSize={(id)=>{}}  onDelete={this.deleteContentImage.bind(this)} onEdit={(id)=>{}} onChooseFromGallery={(id)=>{}} />
              ))}
              <Box sx={{display:"flex", justifyContent:"end", gap:"24px", alignItems:"center"}}>
                <Typography 
                data-testid='saveAsDraft'
                sx={{fontFamily:"Open Sans", fontWeight:600, fontSize:"18px", color:"#000000", textDecoration:'underline', cursor:"pointer"}}
                onClick={this.saveToDraft.bind(this)}
                >
                  Save as a draft
                </Typography>
                <Button 
                  data-testid='postContentButton'
                  onClick={this.handlePostContent.bind(this)}
                  sx={{
                    ...webStyle.commonButtonStyle,
                    background:'#ffffff',
                    color:"#000000",
                    border:"1px solid #000000",
                    padding:"10px 16px 10px 16px",
                  }}
                >
                  Post now
                </Button>
                <Button 
                  data-testid='scheduleContentButton'
                  onClick={this.handleSchedulePost.bind(this)}
                  variant="contained"
                  sx={{
                    ...webStyle.commonButtonStyle,
                    padding:"10px 16px 10px 16px",
                    background:"#000000",
                    color:"#ffffff",
                    '&:hover':{
                      background:"#000000"
                    }
                  }}
                >
                  Save and Schedule
                </Button>
              </Box>
            </StyledContentCustomizationContainer>
          </StyledContentContainer>
        </Box> 
      </BreadcrumbNavigationPage>
    );
  }
}


const StyledBoostButton = styled(Button)({
  borderRadius:"20px",
  background: "#364AC6",
  fontSize:"18px",
  fontFamily:"Open Sans",
  fontWeight:600,
  height:"33px",
  color:"#ffffff",
  textTransform:"none",
  width:"74px",
  textAlign:"center",
  display:"none",
  padding:"4px 12px 4px 12px",
  "&:hover":{
    background: "#364AC6"
  }
})

const StyledGenerateEditButtonContainer= styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center",
  gap:"24px",
  '@media (max-width: 415px)': {
    justifyContent:"center",
    flexDirection:"column",
    alignItems:"start"
  },
})

const StyledContentContainer = styled(Box)({
  flexGrow:1,
  display: "flex", 
  width:"100%",
  gap:"24px",
  '@media (max-width: 1190px)': {
    flexDirection:"column",
    gap:"24px"
   },
})

const StyledContentCustomizationContainer = styled(Box)({
  display:'flex',
  flexDirection:"column", 
  gap:"32px", 
  width:"calc(100% - 410px)",
  padding:"0px 24px 12px 24px",
  '@media (max-width: 1190px)': {
    padding:"0px",
    width:'100%'
   },
})
const StyledContentPreviewContainer = styled(Box)({
  display:'flex',
  width:'400px',
  height:"fit-content",
  alignItems:"center",
  flexDirection:"column",
  paddingTop:"20px",
  '@media (max-width: 1190px)': {
    width:'100%'
   },
})


const webStyle ={
  pageHeadingContainer:{
    display:"flex",
    alignItems:"center",
    gap:"20px",
  },
  commonButtonStyle:{
    borderRadius:"8px",
    textTransform:"none",
    textAlign:"center",
    fontFamily:"Open Sans",
    fontSize:"16px",
    fontWeight:600,
  }

}
// Customizable Area End
