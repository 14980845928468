// Customizable Area Start
import React from "react";
import {
    Box,
    CircularProgress,
} from "@mui/material";
import Footerbar from "../../../components/src/Footerbar";
import Header from "./components/Header"
import { BackToMainPageButton, StyledContainerWithLeftMargin, StyledContainerWithScrollBar, } from "../../../components/src/StyledComponents";
import PrivacyPolicyController, {
    Props,
} from "./PrivacyPolicyController";

export default class PrivacyPolicy extends PrivacyPolicyController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <>
                <Header />
                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, }}>
                    {!this.state.privacyPolicy ? (
                        <Box sx={{ display: "flex", height: "100%", justifyContent: 'center', alignItems: "center" }}>
                            <CircularProgress sx={{ color: "black" }} />
                        </Box>
                    ) : (
                        <StyledContainerWithLeftMargin data-testid='PrivacyPolicyContainer'>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: '24px' }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }}>
                                    <span style={webStyles.inActiveNav}>Main Page/</span>
                                    <span style={webStyles.activeNav}>Privacy Policy</span>
                                </Box>
                                <StyledContainerWithScrollBar>
                                    {this.state.privacyPolicy?.attributes?.description && (
                                        <Box style={{ fontFamily: "Open Sans", maxWidth: "1000px", marginRight: "20px" }}
                                            dangerouslySetInnerHTML={{ __html: this.state.privacyPolicy.attributes.description }}
                                        />)}
                                </StyledContainerWithScrollBar>
                                <BackToMainPageButton sx={{ width: '100%', maxWidth: "300px" }} onClick={() => { this.props.navigation.navigate("Home") }} data-testid='backToMainPage'>
                                    Back to Main Page
                                </BackToMainPageButton>
                            </Box>
                        </StyledContainerWithLeftMargin>
                    )}
                </Box>
                <Footerbar isActive={false} />
            </>
        );
    }
}

const webStyles = {
    activeNav: {
        fontFamily: "Open Sans",
        fontSize: 'clamp(18px, 4vw, 24px)',
        fontWeight: 400,
        color: "#000000",
    },
    inActiveNav: {
        fontFamily: "Open Sans",
        fontSize: 'clamp(14px, 4vw, 18px)',
        fontWeight: 400,
        color: "#5F5F5F",
    },
}
// Customizable Area End
