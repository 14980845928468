// Customizable Area Start
export const productIcon = require('../assets/instaProduct.png').default;
export const blogIcon = require('../assets/blog.png').default;
export const subcriberIcon = require('../assets/subcriber.png').default;
export const backIcon = require('../assets/backIcon.png').default;
export const fbIcon = require('../assets/fb.png').default;
export const instaIcon = require('../assets/insta.png').default;
export const postIcon = require('../assets/postIcon.png').default;
export const ticktockIcon = require('../assets/ticktock.png').default;
export const xIcon = require('../assets/view_.png').default;
export const carouselTypeIcon = require('../assets/type_carousel.png').default;
export const postsTypeIcon = require('../assets/type_posts.png').default;
export const reelsTypeIcon = require('../assets/type_reels.png').default;
export const storiesTypeIcon = require('../assets/type_stories.png').default;
// Customizable Area End
