import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Box,

} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from '@mui/material/Avatar';

const notificationbell = require("./notificationbell.svg").default


import { styled } from '@mui/material/styles';
interface NavbarProps {
  handleopendrawer: () => void;
  fetchUserName: string
  fetchImageUrl:string
}

const Navbar: React.FC<NavbarProps> = ({ handleopendrawer ,fetchUserName,fetchImageUrl}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    handleopendrawer()

  };



  return (
    <Box>
      <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#FFFFFF' }}>
        <Toolbar >
          <IconButton
            edge="start"
            color="success"
            aria-label="menu"
            onClick={handleDrawerOpen}
            sx={{
              display: { xs: "block", sm: "block", md: "none", lg: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>



          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" }
            }} justifyContent={'end'} width={'100%'}
          >

            <Box display={'flex'} gap={2}>

              <StyledMenuTypo
              >
                <Box>

                  <img src={notificationbell} alt="avtar" style={{ marginTop: '2px' }} />
                </Box>

              </StyledMenuTypo>
              <StyledMenuTypo
              >
                Hi,{fetchUserName ? fetchUserName : "User Name"}
              </StyledMenuTypo>
              <Avatar src={fetchImageUrl ? fetchImageUrl : "/broken-image.jpg"} />

            </Box>

          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={drawerOpen}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: "100%",
            height: "auto",
          },
        }}
      >

      </Drawer>
    </Box>
  );
};

export default Navbar;
const StyledMenuTypo = styled(Typography)({

  fontFamily: 'Open Sans',
  margin: "2px",
  cursor: 'pointer',
  fontWeight: 400,
  fontSize: '18px',
  color: '#000000',

});



