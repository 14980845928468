import React from 'react';
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogContent,
  IconButton,
  Modal,
  Paper
} from '@mui/material';
import { imageIcon, videoIcon, deletesIcon, renameIcon, playIcon, cloudUploadIcon } from './assets';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import PhotosLibraryController, { Props, configJSON } from './PhotosLibraryController.web';
import DragAndDropImageSelector from "../../../components/src/DragAndDropImageSelector"
import BreadcrumbNavigationPage from '../../breadcrumbnavigation/src/BreadcrumbNavigationPage.web';
// Customizable Area End

export default class PhotosLibrary extends PhotosLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMyLibraryHeader = () => {
    return (
      <LibraryHeaderBox>
        <PhotoLibraryHeading>
          <Typography className="pageHeading">{configJSON.myLibrary}</Typography>
          <Button onClick={this.openUploadModal} data-testId="addContentTestID" className="newCampaignButton" endIcon={<AddIcon className='heading_icon'/>}>
            {configJSON.addContent}
          </Button>
        </PhotoLibraryHeading>
        <PhotoLibrarySearchContainer>
          <SearchTextField
            placeholder="Search"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <PhotosLibraryFormControl>
            <Typography className='sort_heading'>{configJSON.shortBy}</Typography>
            <StyledSelect fullWidth  IconComponent= {KeyboardArrowDownTwoToneIcon}>
              <MenuItem className='sort_Item' value={10}>{configJSON.nameAZ}</MenuItem>
              <MenuItem className='sort_Item' value={20}>{configJSON.nameZA}</MenuItem>
              <MenuItem className='sort_Item' value={30}>{configJSON.Type}</MenuItem>
              <MenuItem className='sort_Item' value={40}>{configJSON.dateLH}</MenuItem>
              <MenuItem className='sort_Item' value={50}>{configJSON.dateHL}</MenuItem>
            </StyledSelect>
          </PhotosLibraryFormControl>
        </PhotoLibrarySearchContainer>
      {this.renderContentUploadModal()}
      </LibraryHeaderBox>)
  }

  renderContentUploadModal = () => {
    return (
      <ModalContainer
        open={this.state.openUploadModal}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className="deleteModal"
      >
        <Paper className="paper_upload">
          <IconButton
            onClick={this.closeUploadModal}
            className="closeIconBtn"
            data-testId="closeUploadTestID"
          >
            <CloseIcon />
          </IconButton>
          <UploadHeadBox>
            <BoldHeadTypo data-testId="closeLabelTestID">{configJSON.chooseFiles}</BoldHeadTypo>
            <MediumHeadTypo>{configJSON.choiceText}</MediumHeadTypo>
          </UploadHeadBox>
          <DragAndDropImageSelector onSelect={this.handleUploadChange}>
            <UploadContentBox>
              <img src={cloudUploadIcon} />
              <UploadContent>
                <BoldTypo>{configJSON.dragDropText}</BoldTypo>
                <MediumTypo>{configJSON.formatsText}</MediumTypo>
              </UploadContent>
              <UploadBrowseBtn>{configJSON.browseBtn}</UploadBrowseBtn>
            </UploadContentBox>
          </DragAndDropImageSelector>
        </Paper>
      </ModalContainer>
    )
  }

  renderPhotoLibrary = () =>{
    return(
      <CardBox>
      <Box className="headBox">
        <img src={imageIcon} alt="" />
        <Typography data-testId="openImageLabelTestID">{configJSON.imagesText}</Typography>
      </Box>
      <Box className="cardsBox" >
        {this.state.cardData.map((item, index) => (
          <Box className="imageCard" key={index} 
          data-testId="openImageTestID"
          onClick={() => this.openImageModal(item.imageURl)}
          >
            <Box className="imageBox">
              <img src={item.imageURl} alt="" width="100%" height="100%" />
            </Box>
            <Box className="titleBox">
              <Typography data-testId="moreImageLabelTestID">{item.name}</Typography>
              <MoreVertIcon
                className={this.state.openImageMenuIndex === index ? "moreButtonActive" :"moreButton"}
                data-testId="moreImageTestID"
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleMenu(index);
                }}
              />
              {this.state.openImageMenuIndex === index && (
                   <Box className="moreMenu" >
                    <Button className="deleteBtn"  data-testId="deleteBtnTestID"
                      onClick={(event) => { event.stopPropagation();
                                             this.openDeleteModal() }} >
                      <img src={deletesIcon} alt=" "/>
                      <Typography data-testId="deleteBtnTextTestID">{ configJSON.deleteText }</Typography>
                    </Button>
                  <Button className="renameBtn" data-testId="renameBtnTestID"
                    onClick={(event) => { event.stopPropagation();
                                   this.openRenameModal(item.name) }} >
                       <img src={renameIcon} alt=" "/>
                      <Typography data-testId="renameBtnTextTestID">{ configJSON.renameText }</Typography>
                      </Button>
                    </Box>  )}
                 </Box>
                </Box>
                 ))}
            </Box>
           </CardBox>
           )
           }

  renderVideoLibrary = () =>{
    return(
      <CardBox>
          <Box className="headBox">
            <img src={videoIcon} alt="" />
            <Typography>{configJSON.videosText}</Typography>
          </Box>
          <Box className="cardsBox">
            {this.state.cardData.map((item, index) => (
              <Box className="imageCard" key={index} 
              data-testId="openImageTestID"
              onClick={() => this.openImageModal(item.imageURl)}
              >
                <Box className="imageBox">
                  <img src={item.imageURl} alt="" width="100%" height="100%" />
                  <img src={playIcon} className="playBtn" />
                </Box>
                <Box className="titleBox">
                  <Typography data-testId="moreVideoLabelTestID">{item.name}</Typography>
                  <MoreVertIcon
                    className={this.state.openVideoMenuIndex === index ? "moreButtonActive" :"moreButton"}
                    data-testId="moreVideoTestID"
                    onClick={(event) => {
                      event.stopPropagation()
                      this.handleVideoMenu(index);
                    }}
                  />
                  {this.state.openVideoMenuIndex === index && (
                    <Box className="moreMenu">
                      <Button className="deleteBtn"
                       data-testId="deleteBtnTestID"
                       onClick={(event) => {
                        event.stopPropagation();
                        this.openDeleteModal();
                      }}
                      >
                        <img src={deletesIcon} alt="" />
                        <Typography data-testId="deleteBtnTextTestID">{configJSON.deleteText}</Typography>
                      </Button>

                      <Button className="renameBtn"
                       data-testId="renameBtnTestID"
                       onClick={(event) => {
                        event.stopPropagation();
                        this.openRenameModal(item.name);
                      }}
                      >
                        <img src={renameIcon} alt="" />
                        <Typography data-testId="renameBtnTextTestID">{configJSON.renameText}</Typography>
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </CardBox>
    )
  }

  renderViewModal = () =>{
    return(
      <StyledDialog
      open={!!this.state.selectedImage}
      onClose={this.closeImageModal}
    >
      <DialogContentStyled>
        <Box display="flex" justifyContent="space-between">
          <IconButton
            onClick={this.closeImageModal}
            data-testId="closeImageTestID"
            className="closeIconBtn"
          >
            <CloseIcon data-testId="closeImagelabelTestID"/>
          </IconButton>
        </Box>
        <img
          src={this.state.selectedImage || ""}
          alt="Selected"
          style={{ width: "100%", height: "100%" }}
        />
      </DialogContentStyled>
    </StyledDialog>
    )
  }

  renderDeleteModal = () =>{
    return(
      <ModalContainer
      open={this.state.openDeleteModal}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      className="deleteModal"
    >
      <Paper className="paper">
        <IconButton
          onClick={this.closeDeleteModal}
          data-testId="closeDeleteTestID"
          className="closeIconBtn"
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="body1" data-testId="closeDeleteLabelTestID">
          {configJSON.deleteImageText}
        </Typography>
        <Typography variant="body2">{configJSON.sureDeleteText}</Typography>
        <Box className="buttonBox">
          <Button className="cancleBtn" onClick={this.closeDeleteModal}>
            {configJSON.cancelDeleteText}
          </Button>
          <Button className="deleteBtn">{configJSON.yesDeleteText}</Button>
        </Box>
      </Paper>
    </ModalContainer>
    )
  }

  renderRenameModal = () =>{
    return(
      <ModalContainer
          open={this.state.openRenameModal}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          className="deleteModal"
        >
          <Paper className={this.state.openRenameModal?"renamepaper paper":"paper"}>
            <IconButton
              onClick={this.closeRenameModal}
              data-testId="closeRenameTestID"
              className="closeIconBtn"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="body1" data-testId="closeRenameLabelTestID">
              {configJSON.renameText}
            </Typography>
            <TextField
            value={this.state.renameText}
            onChange={(event)=>{this.handleNameChange(event)}}
            data-testId="renameTestID"
            />
            <Box className="buttonBox">
              <Button className="cancleBtn" onClick={this.closeRenameModal}>
                {configJSON.cancelDeleteText}
              </Button>
              <Button className="deleteBtn">{configJSON.saveText}</Button>
            </Box>
          </Paper>
        </ModalContainer>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <BreadcrumbNavigationPage navigation={this.props.navigation}>
        <MainContainer>
          {this.renderMyLibraryHeader()}
          {this.renderPhotoLibrary()}
          {this.renderVideoLibrary()}
          {this.renderViewModal()}
          {this.renderDeleteModal()}
          {this.renderRenameModal()}
        </MainContainer>
      </BreadcrumbNavigationPage>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  padding: "50px 24px"
})

const LibraryHeaderBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
})

const PhotoLibraryHeading = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  "& .heading_icon": {
    fontSize: "16px"
  },
  "& .pageHeading": {
    fontSize: "32px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    color: "#4D4D4D"
  },

  "& .newCampaignButton": {
    textTransform: 'none',
    color: "#FFFFFF",
    ontSize: "16px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    padding: '10px 40px',
    background: '#000000',
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      background: '#000000',
    }
  },

  '@media (max-width: 646px)': {
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    gap: "12px"
  },
  '@media (max-width: 600px)': {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  '@media (max-width: 463px)': {
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "12px"
  },
})

const PhotoLibrarySearchContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  width: "100%",
  '@media (max-width: 754px)': {
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    gap: "12px"
  },
  '@media (max-width: 600px)': {
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "space-between",
  },
  '@media (max-width: 463px)': {
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "12px"
  },
})

const SearchTextField = styled(TextField)({
  width: "calc(100% - 220px)",
  '& .MuiOutlinedInput-root': {
    fontFamily: "Open Sans",
    height: "48px",
    background: "white",
    '& fieldset': {
      border: "1px solid #DDDDDD",
      borderRadius: "8px",
    },
    '&.Mui-focused fieldset': {
      border: "1px solid #000000",
    },
    '&:hover fieldset': {
      borderColor: '#797979',
    },
    '&.Mui-focused:hover fieldset': {
      borderColor: '#000000'
    },
  },
  '& .MuiInputBase-input': {
    height: '100%',
    width: "100%"
  },
  '@media (max-width: 854px)': {
    width: "calc(100% - 170px)"
  },
  '@media (max-width: 754px)': {
    width: "100%"
  },
  '@media (max-width: 600px)': {
    width: "calc(100% - 170px)"
  },
  '@media (max-width: 463px)': {
    width: "100%"
  },
});

const StyledSelect = styled(Select)({
  height: "48px",
  width: '202px',
  '& fieldset': {
    border: "1px solid #D9D9D9",
    borderRadius: "8px"
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000000',
    borderSize: "1px",
  },
  '&.Mui-focused:hover fieldset': {
    borderColor: '#000000'
  },
  '@media (max-width: 854px)': {
    width: 'clamp(150px, 10vw, 202px)'
  },
  '@media (max-width: 754px)': {
    width: "202px"
  },
  '@media (max-width: 600px)': {
    width: "150px"
  },
  '@media (max-width: 463px)': {
    width: "202px"
  },
  "& .sort_Item":{
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Open Sans",
    color: "#4D4D4D"
  }
})

const PhotosLibraryFormControl = styled(FormControl)({
  display: 'flex',
  flexDirection: "column",
  gap: "4px",
  '& .MuiFormHelperText-root': {
    marginLeft:"0px",
    fontWight:600
  },
  "& .sort_heading" : {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Open Sans",
    color: "#4D4D4D"
  }
});

const CardBox = styled(Box)({
  padding: "10px 0px",
  "& .headBox": {
    display: "flex",
    padding: "10px 0px",
    width: "100%",
    gap: "10px",
    "& .MuiTypography-body1": {
      color: "#4D4D4D",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "22px",
      fontFamily: "Open Sans"
    }
  },
  "& .cardsBox": {
    width: "100%",
    gap: "25px 15px",
    display: "flex",
    flexWrap: "wrap"
  },
  "& .imageCard": {
    background: "#FFFDD8",
    width: "168px",
    height: "168px",
    borderRadius: "8px",
    boxSizing: "border-box",
    "&:hover": {
      border: "1px solid #D9D9D9",
      background: "#FCF670"
    }
  },
  "& .imageBox": {
    height: "125px",
    width: "100%",
    borderRadius: "8px",
    position: "relative"
  },
  "& .titleBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    padding: "10px",
    boxSizing: "border-box",
    width: "100%",
    height: "43px",
    "& .MuiTypography-body1": {
      color: "#4D4D4D",
      fontFamily: "Open Sans",
      fontWeight: 400,
      lineHeight: "21px",
      fontSize: "14px"
    }
  },
  "& .moreButton": {
    cursor: "pointer"
  },
  "& .moreButtonActive": {
    cursor: "pointer",
    backgroundColor: "#EFEFEF",
    borderRadius: "50%"
  },
  "& .moreMenu": {
    position: "absolute",
    zIndex: 1,
    top: 34,
    right: -85,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .MuiButton-root": {
      backgroundColor: "#fff",
      fontFamily: "Open Sans",
      textTransform: "none",
      color: "#4D4D4D",
      lineHeight: "21px",
      fontWeight: 400,
      gap: "10px",
      fontSize: "14px",
      width: "116px",
      height: "36px",
      boxSizing: "border-box",
      justifyContent: "flex-start",
      padding: "0px 16px",
      "& img": {
        width: "18px",
        height: "18px"
      }
    }
  },
  "& .deleteBtn": {
    borderTopRightRadius: "8px",
    border: "1px solid #DDDDDD",
    borderTopLeftRadius: "8px",
    borderBottomRightRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  "& .renameBtn": {
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
    border: "1px solid #DDDDDD",
    borderTop: "none"
  },
  "& .playBtn": {
    position: "absolute",
    top: "40%",
    left: "45%",
    cursor: "pointer"
  }
});

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    backgroundColor: "#4D4D4D",  
    width: "1100px",
    height: "730px",
    boxSizing: "border-box",
    borderRadius: "20px",        
    padding: "48px",
    "& img": {
      objectFit: "contain"
    }  
  },
});

const DialogContentStyled = styled(DialogContent)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "0",
  "& .closeIconBtn": {
      position: "absolute",
      color: "#FFFFFF",
      top: "10px",
      right: "10px"
  }
});

const ModalContainer = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  "& .paper": {
    maxHeight:'fit-content',
    width:"fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    borderRadius: "24px",
    gap: "20px 0px",
    position: "relative",
    boxSizing: "border-box",
    "& .MuiTypography-body1": {
      color: "#4D4D4D",
      lineHeight: "27px",
      fontWeight: 600,
      fontSize: "20px"
    },
    "& .MuiTypography-body2": {
        color: "#4D4D4D",
        lineHeight: "21px",
        fontWeight: 400,
        fontSize: "16px"
      }
  },
  "& .paper_upload": {
    maxHeight:'fit-content',
    width:"fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    borderRadius: "24px",
    gap: "20px 0px",
    position: "relative",
    boxSizing: "border-box",
  },
  "& .renamepaper": {
    height: "246px !important",
    "& .MuiOutlinedInput-input": {
        padding: "4px !important",
        borderRadius: "8px",
        border: "1px solid #DDDDDD",
        width: "450px"
    }
},
  "& .closeIconBtn": {
    position: "absolute",
    color: "black",
    top: "10px",
    right: "10px"
  },
  "& .buttonBox": {
    display: "flex",
    gap: "20px",
    padding: "20px 0px",
    "& .MuiButton-root": {
        textTransform : "none",
        borderRadius: "8px",
        padding: "10px 16px",
      lineHeight: "24px",
      fontWeight: 600,
      fontSize: "16px",
      width: "212px",
      height: "48px"
    },
    "& .cancleBtn": {
        color: "#000000",
        border: "1px solid #000000"
    },
    "& .deleteBtn": {
        color: "white",
        backgroundColor: "#000000"
    }
  }
});

const BoldHeadTypo = styled(Typography)({
  fontFamily: 'Open Sans',
  fontWeight: 600,
  textAlign:"center",  
  fontSize: '20px',
  color: '#4D4D4D',
})

const MediumHeadTypo = styled(Typography)({
  fontFamily: 'Open Sans',
  fontWeight: 400,
  fontSize: '16px',
  color: '#4D4D4D',
  textAlign:"center",
})

const BoldTypo = styled(Typography)({
  fontFamily: 'Open Sans',
  fontWeight: 600,
  textAlign:"center",  
  fontSize: '16px',
  color: '#4D4D4D',
})

const MediumTypo = styled(Typography)({
  fontFamily: 'Open Sans',
  fontWeight: 400,
  fontSize: '14px',
  color: '#4D4D4D',
  textAlign:"center",
})

const UploadHeadBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: '100%',
  flexDirection: "column"
})

const UploadContentBox = styled(Box)({
  border: '2px dashed #B3B3B3',
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '8px',
  padding: 'clamp(19px, 3.3vw, 32px)',
  gap: 'clamp(19px, 3.3vw, 32px)'
})

const UploadContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "6px"
})

const UploadBrowseBtn = styled(Box)({
  border: '1px solid #B3B3B3',
  color: '#000000',
  fontWeight: 600,
  fontSize: '16px',
  padding: '16px 32px',
  borderRadius: '8px',
  fontFamily: 'Open Sans',
  display: 'flex',
  justifyContent: "center",
  alignItems: "center"
})
// Customizable Area End 