// Customizable Area Start
import React from "react";
import {
  Box,
} from "@mui/material";
import SubscriptionCard from './components/SubscriptionCard'
import CarouselContainer from './components/CarouselContainer'
import Footerbar from "../../../components/src/Footerbar"
import CustomisableusersubscriptionsController, {
  Props,
} from "./CustomisableusersubscriptionsController";

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
    <Box sx={{display:'flex', flexGrow:1,flexDirection:"column", gap:'24px'}}>
      <Box sx={webStyles.breadcrumbNavContainer}>
        <span style={webStyles.inActiveNav}>Main Page/</span>
        <span style={webStyles.activeNav}>Subscription Plans</span>
      </Box>
     <div style={{display:'flex', flexGrow:1, justifyContent:"center", alignItems:"center"}}>
      <CarouselContainer>
        {this.state.subscriptions.map((plan, index)=>(<SubscriptionCard
          data-testid={`subscriptionCard${index}`}
          name={plan.attributes.plan_name}
          id={plan.id}
          duration={plan.attributes.duration}
          features={plan.attributes.features}
          onSubscribe={this.onSubscribe.bind(this)}
          amount={plan.attributes.amount}
          image={plan.attributes.image_link}
          key={index}
          />))}
        </CarouselContainer>
      </div>
      <Footerbar isActive={false}/>
      </Box>
    );
  }
}

const webStyles ={
  breadcrumbNavContainer:{
    display:"flex",
    alignItems:"center",
    gap:"10px",
    marginTop:"20px",
    pl:"20px"
  },
  inActiveNav:{
    fontFamily:"Open Sans",
    fontSize:'clamp(14px, 4vw, 18px)',
    fontWeight:"400",
    color:"#5F5F5F",
  },
  activeNav:{
    fontFamily:"Open Sans",
    fontSize:'clamp(18px, 4vw, 24px)',
    fontWeight:"400",
    color:"#000000",
  }
  
}

// Customizable Area End
