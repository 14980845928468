// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  FormControl,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EmailAccountRegistrationController, {
    Props
  } from "./EmailAccountRegistrationController";
import { googleIcon, registrationImage, validCheck, invalidCheck } from "./assets";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
        <Box sx={outer} data-test-id='signUpFormContainer' onClick ={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {event.stopPropagation()}}>   
        <StyledContainer>
            <StyledInnerContainer>                
                <StyledLeftContainer>
                    <Box style={{display:'flex', flexDirection:"column", gap:"4px"}}>
                        <Typography style={{fontFamily:"Open Sans", fontSize:"22px" , fontWeight:700, color:"#000000"}}>
                            Welcome to DatAI!
                        </Typography>
                        <Typography style={{fontFamily:"Open Sans", fontSize:"12px" , fontWeight:600, color:"#000000"}}>
                            Register new Account
                        </Typography>
                    </Box>
                    <Box style={{display:'flex', flexDirection:"column", gap:"10px"}}>
                        <StyledFormControl error={this.state.usernameError} >
                            <StyledLabel data-error={this.state.usernameError}>Username</StyledLabel>
                            <StyledTextField
                                data-test-id='usernameInput'
                                aria-invalid={this.state.usernameError}
                                placeholder="Username"
                                onChange={this.handleChangeUsername.bind(this)}
                                value={this.state.registrationUsername}
                                error={this.state.usernameError}
                                helperText={this.state.usernameErrormessage}
                            />
                        </StyledFormControl>
                        <StyledFormControl error={this.state.firstNameError}>
                            <StyledLabel data-error={this.state.firstNameError}>First Name</StyledLabel>
                            <StyledTextField
                                data-test-id='firstNameInput'
                                aria-invalid={this.state.firstNameError}
                                placeholder="First Name"
                                onChange={this.handleChangeFirstName.bind(this)}
                                value={this.state.firstName}
                                error={this.state.firstNameError}
                                helperText={this.state.firstNameErrorMessage}
                            />
                        </StyledFormControl>
                        <StyledFormControl error={this.state.lastNameError}>
                            <StyledLabel data-error={this.state.lastNameError}>Last Name</StyledLabel>
                            <StyledTextField
                                data-test-id='lastNameInput'
                                aria-invalid={this.state.lastNameError}
                                placeholder="Last Name"
                                onChange={this.handleChangeLastName.bind(this)}
                                value={this.state.lastName}
                                error={this.state.lastNameError}
                                helperText={this.state.lastNameErrorMessage}
                            />
                        </StyledFormControl>
                        <StyledFormControl error={this.state.emailError}>
                            <StyledLabel data-error={this.state.emailError}>Email Address</StyledLabel>
                            <StyledTextField
                                data-test-id='emailInput'
                                aria-invalid={this.state.emailError}
                                placeholder="Email Address"
                                onChange={this.handleChangeEmail.bind(this)}
                                value={this.state.registrationEmail}
                                error={this.state.emailError}
                                helperText={this.state.emailErrorMessage}
                            />
                        </StyledFormControl>
                        <StyledFormControl error={this.state.passwordError}>
                            <StyledLabel data-error={this.state.passwordError}>Password</StyledLabel>
                            <StyledTextField
                                data-test-id='passwordInput'
                                type={this.state.showPassword?'text':"password"}
                                placeholder="Password"
                                onChange={this.handleChangePassword.bind(this)}
                                value={this.state.registrationPassword}
                                error={this.state.passwordError}
                                aria-invalid={this.state.passwordError}
                                hidden
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment data-testid='showPasswordContainer' data-show={this.state.showPassword} position="end">
                                        {this.state.showPassword ? 
                                            <VisibilityOutlinedIcon data-testid="hidePassword" onClick={()=>{this.setState({showPassword:false})}}/>:
                                            <VisibilityOffOutlinedIcon data-testid="showPassword" onClick={()=>{this.setState({showPassword:true})}}/>}
                                      </InputAdornment>
                                    ),
                                }}
                            />
                            <Typography style={{fontFamily:"Open Sans", fontSize:"11px" , fontWeight:400, color:"#9A9A9A"}}> 
                                *8 alphanumeric characters, 1 special character, and to include capital letters
                            </Typography>
                        </StyledFormControl>
                        <StyledFormControl error={this.state.passwordError}>
                            <StyledLabel data-error={this.state.passwordError}>Confirm Password</StyledLabel>
                            <StyledTextField
                                data-test-id='confirmPasswordInput'
                                type={this.state.showConfirmPassword?'text':"password"}
                                placeholder="Confirm Password"
                                value={this.state.registrationConfirmPassword}
                                onChange={this.handleChangeConfirmPassword.bind(this)}
                                error={this.state.passwordError}
                                aria-invalid={this.state.passwordError}
                                helperText={this.state.passwordErrorMessage}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end" data-testid='showConfirmPasswordContainer' data-show={this.state.showConfirmPassword} >
                                        {this.state.showConfirmPassword ? 
                                            <VisibilityOutlinedIcon data-testid="hideConfirmPassword" onClick={()=>{this.setState({showConfirmPassword:false})}}/>:
                                            <VisibilityOffOutlinedIcon data-testid="showConfirmPassword" onClick={()=>{this.setState({showConfirmPassword:true})}}/>}
                                      </InputAdornment>
                                    ),
                                }}
                            />
                        </StyledFormControl>
                        <Box style={{display:"flex", flexDirection:"column", gap:"6px", marginLeft:"5px"}}>
                            <Box style={{display:"flex", gap:"8px"}}>
                                <img data-test-id='special-char'src={this.state.hasSpecialCharacter? validCheck:invalidCheck} style={{height:'13px', width:"14px"}}/>
                                <Typography style={{fontSize:"12px", color:"#494949", fontFamily:"Open Sans",fontWeight:400}}>{"At least one special character"}</Typography>
                            </Box>
                            <Box style={{display:"flex", gap:"8px"}}>
                                <img data-test-id='valid-length'src={this.state.isValidPasswordLength? validCheck:invalidCheck} style={{height:'13px', width:"14px"}}/>
                                <Typography style={{fontSize:"12px", color:"#494949", fontFamily:"Open Sans",fontWeight:400}}>{"Minimum character length is 8 characters"}</Typography>
                            </Box>
                            <Box style={{display:"flex", gap:"8px"}}>
                                <img data-test-id='one-number'src={this.state.hasNumber? validCheck:invalidCheck} style={{height:'13px', width:"14px"}}/>
                                <Typography style={{fontSize:"12px", color:"#494949", fontFamily:"Open Sans",fontWeight:400}}>{"At least one number"}</Typography>
                            </Box>
                        </Box>
                        <Box style={{display:"flex", alignItems:"start", gap:"12px"}}>
                            <Box 
                                data-test-id='privacyCheck'
                                style={{
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    height:"24px",
                                    width:'24px',
                                    border:'1px solid #64748B',
                                    borderRadius:"6px"
                                }}
                                onClick={()=>{this.setState({privacyCheck:!this.state.privacyCheck})}}
                            >
                                {this.state.privacyCheck && <img src={validCheck} data-test-id='privacyImage' style={{height:'16px', width:"16px"}}/>}
                            </Box>
                            <Typography style={{fontFamily:"Open Sans",fontSize:'12px', width:`calc(100% - 26px)`, fontWeight:400, color:"#000000"}}>
                                By registering you agree with DatAI’s 
                                <span data-testid='privacyPolicyLink' style={{color:"#4865FF",fontWeight:600, cursor:"pointer"}} onClick={this.handleClickOnPrivacy.bind(this)}> Privacy Policy </span> and 
                                <span data-testid='termsConditionLink'onClick={this.handleClickOnTermsAndConditions.bind(this)} style={{color:"#4865FF",fontWeight:600, cursor:"pointer"}}> Terms and Conditions </span>
                            </Typography>
                        </Box>
                        <Button
                            data-test-id="registerButton"
                            fullWidth
                            style={{
                                color:"#ffff",
                                background:this.state.privacyCheck?"#202020":"#C2C2C2",
                                borderRadius:"8px",
                                textTransform:"capitalize",
                                minHeight:"42px",
                                height:"fit-content",
                                fontSize:"16px",
                                fontWeight:"600"
                            }}
                            disabled={!this.state.privacyCheck}
                            onClick={this.handleRegister.bind(this)}
                        >
                            Register
                        </Button>
                        <Box style={{display:'flex', gap:"14px", justifyContent:"center", alignItems:"center"}}>
                            <StyledDividerLine/>
                            <Typography style={buttonText}>Or register with</Typography>
                            <StyledDividerLine/>
                        </Box>
                        <Box 
                            style={{
                                display:'flex',
                                gap:"8px",
                                padding:"2.5px",
                                justifyContent:"center",
                                alignItems:"center",
                                border:"1px solid #000000",
                                borderRadius:"8px",
                                height:"fit-content",
                                minHeight:"35px"
                            }}
                        >
                                <img src={googleIcon}/>
                                <span style={buttonText}>
                                    Register with Google
                                </span>
                        </Box>
                        <Typography style={{fontFamily:"Open Sans", fontSize:"12px",fontWeight:400,color:"#000000",textAlign:"center"}}>
                            Already have an account? <span style={{fontWeight:700, cursor:"pointer"}} data-test-id='goToLogin' onClick={this.sendOpenLoginFormMessage}> Log In</span>
                        </Typography>
                    </Box>
                </StyledLeftContainer>
                <StyledRightContainer>
                    <img src={registrationImage} style={imageStyle}/>
                </StyledRightContainer>               
            </StyledInnerContainer>
        </StyledContainer> 
            <StyledCloseIcon data-testid={"closeButton"}  onClick={this.sendCloseFormMessage}/>
        </Box>         
    );
  }
}
const StyledContainer = styled(Box)({
    display:"flex",
    background:"#FFFFFF",
    maxHeight:'85vh',
    width:"fit-content",
    borderRadius:"24px",
    padding:"30px",
    overflowY:"auto",
      '@media (max-height: 615px)': {
        maxHeight:'82vh',
      },
      '@media (max-height: 530px)': {
        maxHeight:'80vh',
      },
      '@media (max-height: 430px)': {
        maxHeight:'76vh',
      },
      '@media (max-height: 350px)': {
        maxHeight:'71vh',
      },
      '@media (max-height: 290px)': {
        maxHeight:'65vh',
      },
      '@media (max-height: 250px)': {
        maxHeight:'50vh',
      },
      '@media (max-height: 200px)': {
        maxHeight:'40vh',
      },
      '@media (max-height: 100px)': {
        maxHeight:'25vh',
      },
    "::-webkit-scrollbar": {
        display: 'none',
      },
      "msOverflowStyle": 'none',
      "scrollbarWidth": 'none'
});
const outer ={
    display:"flex",
    background:"#FFFFFF",
    maxHeight:'fit-content',
    width:"fit-content",
    borderRadius:"24px",
    margin:"10px",
    position:"relative",
    border:"10px solid #FFFFFF"
}
const StyledCloseIcon = styled(CloseOutlinedIcon)({
    position: "absolute",
    color: "#878787",
    fontSize: "24px",
    cursor: "pointer",
    zIndex:5,
    top:11,
    right:11,
});
const StyledInnerContainer=styled(Box)({
    display:"flex",
    justifyContent:"center",
    alignItems:"center",    
    gap:'40px',
    height:"100%",
    '@media (max-width: 858px)': {
        flexDirection: 'column-reverse',
        justifyContent:"start",
        gap:"0px",
    },
    
})
const StyledDividerLine = styled(Box)({
    display:"flex",
    height:'1px',
    flex:1,
    background:"#000000",
})
const imageStyle={
    width:"100%",
    objectFit: 'cover' as 'cover',
    objectPosition: "center",
    overflow: "hidden",
}
const StyledLeftContainer=styled(Box)({
    display:"flex",
    flexDirection :'column',
    gap:"24px",
    width:"300px",
    height:"100%",
    '@media (max-width: 858px)': {
        width:"100%",
    },
})
const StyledRightContainer=styled(Box)({
    display:'flex',
    flexDirection :'column',
    maxWidth:"456px",
    // border:"1px solid black",
    justifyContent:"center",
    height: '151px',
})
const buttonText={
    fontFamily:"Open Sans",
    fontSize:"14px",
    fontWeight:400,
    color:"#000000"
}

const StyledTextField = styled(TextField) ({
    '& .MuiOutlinedInput-root': {
     fontFamily: "Open Sans",
      height: "48px",
      borderRadius: "8px",
      '& fieldset': {
        borderColor: '#6F6F6F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6F6F6F',
      },
      '&:hover fieldset': {
        borderColor: '#6F6F6F',
      },
    },
    '&[aria-invalid="true"] .MuiOutlinedInput-root':{
        '&.Mui-focused fieldset': {
            borderColor: '#E04545',
          },
          '&:hover fieldset': {
            borderColor: '#E04545',
          },
          '& fieldset': {
            borderColor:'#E04545',
          },
    },
    '& .MuiInputBase-input': {
      height: '100%', 
    },
});

const StyledFormControl = styled(FormControl)({
    display: 'flex',
    flexDirection: "column",
    gap: "4px",
    '& .MuiFormHelperText-root': {
      marginLeft:"0px",
      fontWight:600
    },
});

const StyledLabel = styled(Typography)({
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 600,
    color:"#000000",
    '&[data-error="true"]':{
        color: '#E04545',
    },
});
// Customizable Area End
