// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.patchApiMethod = "PATCH";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "chatgpt";
exports.labelBodyText = "chatgpt Body";

exports.btnExampleTitle = "CLICK ME";
exports.letsCreate = "Let's create something";
exports.generate = "Generate";
exports.exampleContent = "Eg. I run a online pet store. Create a facebook and an instagram post advertising 50% off dog food this Friday. Schedule it to post at 1pm on Thursday.";
exports.usefulContent = "Some useful starting points";
exports.productContent = "Instagram post for new products";
exports.campaignContent = "Facebook carousel for new campaign";
exports.subcriberContent = "Newsletter for subscribers";
exports.blogContent = "Blog to reach new goals";
exports.GuideContent = "Don’t know what to do?";
exports.GuidedContent = "Try our guided content creation walkthrough";
exports.GuideBtnContent = "Start Guided Walkthrough";
exports.addContent = "Add content";
exports.contentText = "What type of content would you like to create today?";
exports.nextBtn = "Next";
exports.imageVideoText = "Do I need to generate images and/or video for this post, or will you be using your own content?";
exports.aboutPostText = "What is this post about?";
exports.postAddContentEndPoint = "bx_block_campaigns/accounts/";
exports.postGenerateContentEndPoint = "bx_block_jarvys_content_management/generate_post_details/";
exports.postCampaignCreateEndPoint = "bx_block_campaigns/accounts/";

// Customizable Area End
