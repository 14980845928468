// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities"
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
export const configJSON = require("./config");


export interface Props {
  navigation: any;
}

export interface Image {
  id: number;
  image_url: string;
  filename: string;
  content_type: string;
  byte_size: number
}

export interface Caption {
  id: number;
  post_id: number;
  content: string;
  selected: boolean
  created_at: string;
  updated_at: string;
}

export interface ContentDetails {
  id: number
  type: string
  attributes: {
    id: number
    name: string;
    content: string;
    post_type: string;
    draft: boolean;
    created_at: string;
    updated_at: string;
    social_media_platform: {
      id: number;
      name: string;
      account_id: number;
      created_at: string;
      updated_at: string;
    },
    captions: Caption[],
    images: Image[]
    videos: {
      id: number;
      video_url: string;
      filename: string;
      content_type: string;
      byte_size: number
    }
  }
}


interface S {
  loading: boolean;
  isRegeneratingCaption: boolean,
  isEditContent: boolean;
  contentImages: Image[];
  activeCaptionIndex: number,
  contentName: string,
  contentCaptions: Caption[],
  contentDetails: ContentDetails | null,
}

interface SS {
  idManagement: number | string;
}
export default class ContentManagementEditController extends BlockComponent<
  Props,
  S,
  SS
> {

  authToken: string = "";
  userId: string = "";
  contentId: string = "";
  getContentApiCallId: string = "";
  regenerateCaptionApiCallId: string = "";
  regenerateImageApiCallId: string = "";
  saveCaptionApiCallId: string = ""
  deleteImageApiCallId: string = "";
  saveAsDraftApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isEditContent: false,
      loading: false,
      contentName: "Post Name",
      contentImages: [],
      activeCaptionIndex: 0,
      contentCaptions: [],
      contentDetails: null,
      isRegeneratingCaption: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  async componentDidMount() {
    this.authToken = await getStorageData('authToken');
    this.userId = await getStorageData('userId');
    const queryParams = new URLSearchParams(this.props.navigation.state.searchParam);
    const contentId = queryParams.get("id")
    if (contentId) {
      this.contentId = contentId
      this.getContentDetails()
    } else {
      this.props.navigation.goBack()
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getContentApiCallId) {
        this.setState({
          contentName: responseJson.data.attributes.name,
          contentImages: responseJson.data.attributes.images,
          contentCaptions: responseJson.data.attributes.captions,
          contentDetails: responseJson.data
        })
      } else if (apiRequestCallId === this.regenerateCaptionApiCallId) {
        this.setState({
          contentCaptions: responseJson.data.attributes.captions,
          contentDetails: responseJson.data,
          isRegeneratingCaption: false,
        })
        toast.success("Captions regenerated!")
      } else if (apiRequestCallId === this.saveCaptionApiCallId) {
        this.setState({
          contentCaptions: responseJson.data.attributes.captions,
          contentDetails: responseJson.data,
          isEditContent: false,
        })
        toast.success("Caption updated successfully!")
      } else if (apiRequestCallId === this.regenerateImageApiCallId) {
        this.handleRegenerateImageResponse(responseJson)
      } else if (apiRequestCallId === this.deleteImageApiCallId) {
      this.getContentDetails();
        this.setState({
          contentImages: responseJson.data.attributes.images,
          contentDetails: responseJson.data,
        })
        toast.success("Image deleted successfully!")
      } else if(apiRequestCallId === this.saveAsDraftApiCallId){
        if(responseJson.data){
          toast.success("Post successfully saved as a draft.")
          this.props.navigation.goBack()
        }else{
          toast.error('Something went wrong, Please try again later!')
        }
      }
    }
  }

handleRegenerateImageResponse(response:{data?:ContentDetails, error?:string}){
  if (response.error) {
    toast.error(response.error)
  }else if(response.data) {
    this.setState({
      contentImages: response.data.attributes.images,
      contentDetails: response.data,
    })
    toast.success("Image regenerated successfully!")
  }else{
    toast.error("Something went wrong, Please try again!")
  }
}
  handleEditManually() {
    this.setState({ isEditContent: true })
  }
  handleChangeCaption(event: ChangeEvent<HTMLInputElement>) {
    const captions = this.state.contentCaptions;
    captions[this.state.activeCaptionIndex].content = event.target.value
    this.setState({ contentCaptions: captions })
  }
  goToNextCaption() {
    if (this.state.activeCaptionIndex < this.state.contentCaptions.length - 1) {
      this.setState({ activeCaptionIndex: this.state.activeCaptionIndex + 1 })
    }
  }
  goToPreviousCaption() {
    if (this.state.activeCaptionIndex > 0) {
      this.setState({ activeCaptionIndex: this.state.activeCaptionIndex - 1 })
    }
  }
  sendRequest = (data: { contentType: string, endPoint: string, method: string, body?: string }) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: this.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  getContentDetails() {
    const requestData = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}`,
      method: configJSON.getMethod,
    }
    this.getContentApiCallId = this.sendRequest(requestData)
  }
  regenerateCaption() {
    this.setState({ isRegeneratingCaption: true })
    const requestData = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}/regenerate_captions`,
      method: configJSON.postMethod,
    }
    this.regenerateCaptionApiCallId = this.sendRequest(requestData)
  }

  saveManuallyWrittenCaption() {
    const requestData = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}/captions/${this.state.contentCaptions[this.state.activeCaptionIndex].id}`,
      method: configJSON.putMethod,
      body: JSON.stringify({ caption: { content: this.state.contentCaptions[this.state.activeCaptionIndex].content } })
    }
    this.saveCaptionApiCallId = this.sendRequest(requestData)
  }

  regenerateImage(imageId: number) {
    const requestData = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}/regenerate_image/${imageId}`,
      method: configJSON.postMethod,
    }
    this.regenerateImageApiCallId = this.sendRequest(requestData)
  }
  deleteContentImage(imageId: number) {
    const requestData = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}/delete_image/${imageId}`,
      method: configJSON.deleteMethod,
    }
    this.deleteImageApiCallId = this.sendRequest(requestData)
  }

  handlePostContent() {
    toast.info("Feature is under development and will be released shortly!")
  }

  handleSchedulePost() {
    toast.info("Feature is under development and will be released shortly!")
  }
  saveToDraft() {
    const requestData = {
      contentType: configJSON.ApiContentTypeJson,
      endPoint: `${configJSON.getCampaignsEndPoint}${this.userId}/posts/${this.contentId}/save_as_draft`,
      method: configJSON.patchMethod,
      body: JSON.stringify({ caption_id: this.state.contentCaptions[this.state.activeCaptionIndex].id, draft: true })
    }
    this.saveAsDraftApiCallId = this.sendRequest(requestData)
  }

}

// Customizable Area End
