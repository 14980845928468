export const deleteIcon = require("../assets/delete.png");
export const checkedIcon = require("../assets/checked.png");
export const uncheckedIcon = require("../assets/unchecked.png");
export const cardImage1 = require("../assets/image1.png").default;
export const cardImage2 = require("../assets/image2.png").default;
export const cardImage3 = require("../assets/image3.png").default;
export const cardImage4 = require("../assets/image4.png").default;
export const cardImage5 = require("../assets/image5.png").default;
export const cardImage6 = require("../assets/image6.png").default;
export const cardImage7 = require("../assets/image7.png").default;
export const imageIcon = require("../assets/imageIcon.png").default;
export const videoIcon = require("../assets/videoIcon.png").default;
export const deletesIcon = require("../assets/deleteIcon.png").default;
export const renameIcon = require("../assets/renameIcon.png").default;
export const playIcon = require("../assets/playIcon.png").default;
export const cloudUploadIcon = require("../assets/cloudUpload.png").default;