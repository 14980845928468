import React from "react";

// Customizable Area Start
import {
  Box,
  CircularProgress,
} from "@mui/material";
import Footerbar from "../../../components/src/Footerbar";
import Header from "./components/Header"

import { BackToMainPageButton, StyledContainerWithLeftMargin, StyledContainerWithScrollBar, } from "../../../components/src/StyledComponents";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Header />
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, }}>
          {!this.state.termsAndConditions ? (
            <Box sx={{ display: "flex", height: "100%", justifyContent: 'center', alignItems: "center" }}>
              <CircularProgress sx={{ color: "black" }} />
            </Box>
          ) : (
            <StyledContainerWithLeftMargin data-testid='termsAndConditionsContainer'>
              <Box sx={{ display: "flex", flexDirection: "column", gap: '24px' }}>
                <Box sx={webStyles.breadcrumbNavContainer}>
                  <span style={webStyles.inActiveNav}>Main Page/</span>
                  <span style={webStyles.activeNav}>Terms & Conditions</span>
                </Box>
                <StyledContainerWithScrollBar>
                  {
                    this.state.termsAndConditions?.attributes?.description && (
                      <div
                        style={{ fontFamily: "Open Sans", maxWidth: "1000px", marginRight: "20px" }}
                        dangerouslySetInnerHTML={{ __html: this.state.termsAndConditions.attributes.description }}
                      />
                    )
                  }
                </StyledContainerWithScrollBar>
                <BackToMainPageButton sx={{ width: '100%', maxWidth: "300px" }} onClick={() => { this.props.navigation.navigate("Home") }} data-testid='backToMainPage'>
                  Back to Main Page
                </BackToMainPageButton>
              </Box>
            </StyledContainerWithLeftMargin>
          )}
        </Box>
        <Footerbar isActive={false} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const webStyles = {
  breadcrumbNavContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginTop: "20px",
  },
  inActiveNav: {
    fontFamily: "Open Sans",
    fontSize: 'clamp(14px, 4vw, 18px)',
    fontWeight: 400,
    color: "#5F5F5F",
  },
  activeNav: {
    fontFamily: "Open Sans",
    fontSize: 'clamp(18px, 4vw, 24px)',
    fontWeight: 400,
    color: "#000000",
  }
}
// Customizable Area End
