// Customizable Area Start
import React from 'react';

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  // Customizable Area Start
  styled,
  TextField,
  Dialog,
  DialogContent,
  IconButton
  // Customizable Area End
} from '@mui/material';

// Customizable Area Start
interface MessageArray {
  id: number;
  message: string;
  isUser: boolean;
}
import BreadcrumbNavigationPage from '../../breadcrumbnavigation/src/BreadcrumbNavigationPage.web';
import { btnbackIcon, closeIcon, dislikeIcon, editIcon, reloadIcon, sendIcon, speakerIcon } from './assets';
import DottedCircularLoader from '../../../components/src/DottedCircularLoader';
// Customizable Area End

import ChatgptController, { Props, configJSON } from './ChatgptController';

export default class Chatgpt extends ChatgptController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start  
    renderGenerateChatPage = () => {
      const { campaignName, isCampaignName ,chatData, nextQuestion } = this.state;
      return(<>
      <Box style={webStyles.mainContainer}>
        <Box style={webStyles.topMsgBar}>
          <img onClick={this.handleChatPageBack} data-testId="backChatPageTestID" style={webStyles.topMsgBarIcon} src={btnbackIcon} alt="" />
         {this.state.editCampaign ? <>
          <Typography  style={webStyles.topMsgBarTxt} data-testId="editTextTestID">{campaignName}</Typography>
          <img style={webStyles.topMsgBarIcon} data-testId="editIconTestID" onClick={this.editCampaignOpen} src={editIcon} alt="" />
         </> : <>
          <TextFieldCampaign value={isCampaignName} data-testId="campaignInputTestID" onChange={this.handleCampaignName}/>
          <img style={webStyles.topMsgBarIcon} data-testId="editIconCloseTestID" onClick={this.editCampaignClose} src={editIcon} alt="" />
         </>}
        </Box>
        {chatData.map((item , index)=>{
          return(
          <Box style={webStyles.chatBox} key={index} >
          <Box style={webStyles.userChatMainBox}>
            <Typography style={webStyles.yourQuesTxt}>Your question </Typography>
            <Box  style={webStyles.userChatBox}>
              <UserChatBox>
                 {item.question}
              </UserChatBox>
            </Box>
          </Box>
          <Box style={webStyles.jarvysChatBox}>
            <Typography style={webStyles.jarvysText}>JARVYS’s answer</Typography>
            <JarvysAnsTextBox  
                dangerouslySetInnerHTML={{
                  __html: item.jarvysAnswer,
                }}
            >
            </JarvysAnsTextBox>
            <Box style={webStyles.jarvysAnsIconBox}>
              <img style={webStyles.jarvysIcon} src={speakerIcon} alt="" />
              <img style={webStyles.jarvysIcon} src={reloadIcon} alt="" />
              <img style={webStyles.jarvysIcon} src={dislikeIcon} alt="" />
            </Box>
          </Box>
        </Box>
        )
        })}

        <StyledDialog
            onClose={this.handleCloseModal}
            aria-labelledby="customized-dialog-title"
            open={this.state.isModalOpen}
        >
            <IconBtn
                aria-label="close"
                data-testId='btnCloseModal'
                onClick={this.handleCloseModal}
            >
                <img src={closeIcon} alt="close_icon" />
            </IconBtn>
            
            <DialogContent dividers>
              <DottedCircularLoader/>
              <Typography style={webStyles.modalTxt} data-testId="btnCloseModalTestID">{"Generating your content..."}</Typography>
            </DialogContent>
        </StyledDialog>
      </Box>
      <InputBox>
        <StyledInputField
            fullWidth
            id="outlined-adornment-password"
            type={'text'}
            placeholder='Enter your text'
            variant='outlined'
            value={nextQuestion}
            onChange={this.handlePostContent}
            data-testId="generateIconTestID"
            InputProps={{
              endAdornment: <IconButton
              disabled = {nextQuestion === "" ? true : false} 
              aria-label="toggle password visibility"
              data-testId="generateTestID"
              onClick={this.handlePostGenerateContent}
                edge="end"
              >
                <img src={sendIcon} alt="" />
              </IconButton>
            }}
          />
        </InputBox>
      </>)
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <BreadcrumbNavigationPage navigation={this.props.navigation}>
           {this.renderGenerateChatPage()}
        </BreadcrumbNavigationPage>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  mainContainer: {
    margin: '32px 24px 100px 24px',
  },
  topMsgBar: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '24px',
  },
  topMsgBarIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  topMsgBarTxt: {
    width: 'fit-content',
    background: '#EFEFEF',
    color: '#4D4D4D',
    border: '0.5px solid #000000',
    padding: '2px 4px 2px 4px',
    margin: '0 20px 0 20px',
  },
  chatBox: {
    margin: '0 20px 0 20px',
  },
  userChatMainBox: {
    marginTop: '24px',
  },
  yourQuesTxt: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 600,
    color: '#4D4D4D',
    textAlign: 'right' as 'right'
  },
  userChatBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
    marginTop: '8px',
  },
  jarvysChatBox: {},
  jarvysText: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 600,
    marginTop: '24px',
    color: '#4D4D4D',
  },
  jarvysAnsIconBox: {
    marginTop: '14px',
    width: 'fit-content',
    display: 'none',
    gridTemplateColumns: 'auto auto auto',
    columnGap: '14px',
  },
  jarvysIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  modalTxt: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center' as 'center',
    color: '#000000',
    marginTop: '55px',
  },
 
}

const UserChatBox = styled(Typography)({
  maxWidth: "67.42%",
  boxSizing: 'border-box' as 'border-box',
  background: '#FFFDD8',
  padding: "8px 16px 8px 16px",
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 400,
  color: '#4D4D4D',
  borderRadius: '16px 0px 16px 16px',
  "@media (max-width: 700px)": {
    maxWidth: "80%",
  },
  "@media (max-width: 600px)": {
    maxWidth: "90%",
  },
});

const JarvysAnsTextBox = styled(Typography)({
  maxWidth: "67.42%",
  marginTop: '8px',
  boxSizing: 'border-box' as 'border-box',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 400,
  color: '#4D4D4D',
  "@media (max-width: 700px)": {
    maxWidth: "80%",
  },
  "@media (max-width: 600px)": {
    maxWidth: "90%",
  },
});
    
const StyledDialog = styled(Dialog) ({
  "& .MuiDialog-paper": {
    width: '529px',
    borderRadius: "12px",
    boxSizing: 'border-box',
  },
  '& .MuiDialogContent-root': {
      paddingTop: '48px',
      paddingBottom: '48px',
  },
});

const IconBtn = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
});

const StyledInputField = styled(TextField)({
  marginBottom: "0px",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#B3B3B3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B3B3B3",
      borderWidth: "1px",
    },
  },
  "& .MuiInputBase-root":{
    fontFamily: 'Poppins',
    height: '66px',
    margin: 'auto',
    borderRadius: '100px',
    backgroundColor: "#FFFFFF",
    fontSize: '16',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderRadius: "100px",
    borderColor: "#B3B3B3",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#B3B3B3",
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: 'Poppins',
    color: '#B3B3B3',
    paddingLeft: '20px',
    paddingTop: "17px",
    "&::placeholder": {
      fontSize: "16",
      fontFamily: 'Poppins',
      color: '#B3B3B3',
    },
    "&:not(:placeholder-shown)": {
      fontFamily: 'Poppins',
      color: '#B3B3B3',
    },
  },
  "& .MuiInputBase-input[type='date']": {
    color:'red',
    textTransform:'uppercase',
    lineHeight: "19.2px",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    fontFamily: "Lato",
    color: "#DC2626",
  }, 
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B3B3B3"
  },
  
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':{
    border:"1px solid #FFFFFF",
  },
  "& .MuiFormHelperText-contained": {
    marginRight: "0px",
    marginLeft: "0px",
  },
});

const InputBox = styled(Box)({
  position: "fixed",
  width: "86%",
  boxSizing: "border-box",
  padding: "10px 24px 10px 24px",
  background: "white",
  bottom: 0,
  "@media (max-width: 1600px)": {
    width: "84%",
  },
  "@media (max-width: 1400px)": {
    width: "82%",
  },
  "@media (max-width: 1200px)": {
    width: "81%",
  },
  "@media (max-width: 1100px)": {
    width: "80%",
  },
  "@media (max-width: 1000px)": {
    width: "79%",
  },
  "@media (max-width: 900px)": {
    width: "100%",
  },
})

const TextFieldCampaign = styled(TextField)({
     margin: "0px 5px",
     backgroundColor: "#EFEFEF",
     border: "0.5px solid #000000",
     outline: "none",
     borderRadius: "none",
    "& .MuiTextField-root":{
      padding: "2px 4px"
    },
    "& .MuiInputBase-input":{
      padding: "2px 4px",
      borderRadius: "none"
    },
    "& .MuiOutlinedInput-notchedOutline":{
      border: "none"
    }
}) 
// Customizable Area End 

// Customizable Area End
