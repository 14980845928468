import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  children:React.ReactNode
  // Customizable Area End
}
interface Children {
  id: number;
  page: string;
}

interface S {
  // Customizable Area Start
  currentChildren: Children[];
  currentBreadcrumbNavigation: (string | number)[];
  txtInputPageName: string;
  menuToggle: boolean;
  fetchUserName:string;
  fetchImageUrl:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class BreadcrumbNavigationPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDocumentId: string = "";
  getDocumentFetchBreadcrumbData: string = "";
  currentGetBreadData: string[] = [];
  currentPageId: string | number | undefined = Infinity;
  postDocumentId: string = "";
  getUserProfileheaderApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentChildren: [],
      currentBreadcrumbNavigation: [Infinity],
      txtInputPageName: "",
      menuToggle: false,
      fetchUserName:"",
      fetchImageUrl:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      runEngine.debugLog("API Response Received", response);

      if (apiRequestCallId === this.getUserProfileheaderApiCallId) {
        if (response) {
          this.setState({fetchUserName: response.data.attributes.first_name,fetchImageUrl:response.data.attributes.profile_image})

        }
      }
    }
    // Customizable Area End
  }

  // web events
  setInputPageName(pageName: string) {
    this.setState({
      txtInputPageName: pageName,
    });
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
     await this.GetuserProfileDetail()
  }


  updateBreadcrumbState(
    responseJsonSuccess: { data: { attributes: { page: string; id: number } } },
    index: number
  ) {
    let dataCurrent = [];
    const current = this.state.currentBreadcrumbNavigation;
    dataCurrent = [...current];
    dataCurrent[
      index + 1
    ] = `${responseJsonSuccess.data.attributes.page}-${responseJsonSuccess.data.attributes.id}`;
    this.setState({
      currentBreadcrumbNavigation: dataCurrent,
    });
  }

  updateBreadcrumbNavigation() {
    this.setState(
      {
        currentBreadcrumbNavigation: [Infinity],
      },
      () => {
        const location = this.props.navigation.state.routeName;
        const breadcrumbId = location
          .split("/")
          .filter((filter: string) => filter)
          .slice(1)
          .slice(-3)
          .map((element: string) => {
            const stringToId = element.split("-");
            return stringToId[stringToId.length - 1];
          });

        breadcrumbId.forEach((breadId: string) => {
          const headers = {
            token:
              "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzksImV4cCI6MTY3NjM1MzE3Nn0.UzFJ2R5Uuyg12KkuX94O3dt2fn7nSYiR7qxLOR3xNnZyDlQeIFrYmNrzKyIjznxFS9jSSFUGGeMbGS3AgoyyUw",
          };

          const getMessage: Message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );

          this.getDocumentFetchBreadcrumbData = getMessage.messageId;

          this.currentGetBreadData.push(this.getDocumentFetchBreadcrumbData);

          getMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_breadcrumbnavigation/breadcrumbnavigations/${breadId}`
          );

          getMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );

          getMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
          );

          runEngine.sendMessage(getMessage.id, getMessage);
        });
      }
    );
  }

  addPageOnPage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const header = {
      "Content-Type": "application/json",
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzksImV4cCI6MTY3NjM1MzE3Nn0.UzFJ2R5Uuyg12KkuX94O3dt2fn7nSYiR7qxLOR3xNnZyDlQeIFrYmNrzKyIjznxFS9jSSFUGGeMbGS3AgoyyUw",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postDocumentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_breadcrumbnavigation/breadcrumbnavigations"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    if (this.currentPageId === Infinity) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            page: this.state.txtInputPageName,
          },
        })
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            page: this.state.txtInputPageName,
            parent_id: this.currentPageId,
          },
        })
      );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDataSuccess(children: Children[]) {
    this.setState({
      currentChildren: children,
    });
  }

  getBreadcrumbData(pageId: string | number | undefined) {
    this.currentGetBreadData = [];

    this.setState({
      txtInputPageName: "",
    });

    const headers = {
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzksImV4cCI6MTY3NjM1MzE3Nn0.UzFJ2R5Uuyg12KkuX94O3dt2fn7nSYiR7qxLOR3xNnZyDlQeIFrYmNrzKyIjznxFS9jSSFUGGeMbGS3AgoyyUw",
    };

    const getMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDocumentId = getMessage.messageId;

    if (pageId !== Infinity) {
      getMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_breadcrumbnavigation/breadcrumbnavigations/${pageId}`
      );
    } else {
      getMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_breadcrumbnavigation/breadcrumbnavigations"
      );
    }

    getMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(getMessage.id, getMessage);
  }

  handleToggleDrawer = () =>{
    this.setState({menuToggle : false});
}

  goToGenerateContent = () => {
    const contentResult = new Message(getName(MessageEnum.NavigationMessage));
    contentResult.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "GenerateContent"
    );
    contentResult.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(contentResult);
}

  goToCommunityManager = () => {
    const contentResult = new Message(getName(MessageEnum.NavigationMessage));
    contentResult.addData(getName(MessageEnum.NavigationTargetMessage), "Dashbored");
    contentResult.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(contentResult);
  }

  goToSeoRecommendations = () => {
    const seoRecommendations = new Message(getName(MessageEnum.NavigationMessage));
    seoRecommendations.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "Cfmarkyaiapiintegration"
    );
    seoRecommendations.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(seoRecommendations);
  }

  goToInteractivefaqs = () => {
    const interactivefaq = new Message(getName(MessageEnum.NavigationMessage));
    interactivefaq.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Interactivefaqs"
    );
    interactivefaq.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(interactivefaq);
  }

  goToOnboardingguide = () => {
    const interactivefaq = new Message(getName(MessageEnum.NavigationMessage));
    interactivefaq.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Onboardingguide"
    );
    interactivefaq.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(interactivefaq);
  }

  goToConnectedAccount = () => {
    const connectedAccount = new Message(getName(MessageEnum.NavigationMessage));
    connectedAccount.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ConnectedAccounts"
    );
    connectedAccount.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(connectedAccount);
  }


  handledrweropensidebar=()=>{

    this.setState({menuToggle:true})
  }


  goToMyLibrary = () => {
    const myLibrary = new Message(getName(MessageEnum.NavigationMessage));
    myLibrary.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PhotosLibrary"
    );
    myLibrary.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(myLibrary);
  }

 


  async GetuserProfileDetail(){
    const token = await getStorageData('authToken');
    const userId = await getStorageData('userId');
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const endPoint = `${configJSON.getHeaderProfileEndPoint}${userId}/logged_user`
    this.getUserProfileheaderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToLandingPage = () => {
    const landingPage = new Message(getName(MessageEnum.NavigationMessage));
    landingPage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Home"
    );
    landingPage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(landingPage);
  }
  // Customizable Area End
}
