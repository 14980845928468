import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import  { ChangeEvent } from 'react';
import { setStorageData } from "../../../framework/src/Utilities";
import { Slide, toast } from "react-toastify";
export interface SignUpSuccessResponse {
  data: {
    id: string,
    type:string,
    attributes: {
        user_name: string,
        email: string
    }
  },
  meta: {
        token: string
  }
}

export interface SignUpErrorResponse {
    email:string[] | undefined,
    username:string[] | undefined,
}
export interface SignUpResponse {
  data: {
    id: string,
    type:string,
    attributes: {
        user_name: string,
        email: string
    }
  },
  meta: {
        token: string
  }
  errors:SignUpErrorResponse
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  firstNameError:boolean;
  firstNameErrorMessage:string;
  lastName: string;
  lastNameError:boolean;
  lastNameErrorMessage:string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  registrationEmail:string;
  registrationPassword:string;
  registrationConfirmPassword:string;
  registrationUsername:string;
  privacyCheck:boolean;
  usernameError:boolean;
  usernameErrormessage:string;
  emailError:boolean;
  emailErrorMessage:string;
  passwordError:boolean;
  passwordErrorMessage:string;
  hasSpecialCharacter:boolean;
  isValidPasswordLength:boolean;
  hasNumber:boolean;
  showPassword:boolean;
  showConfirmPassword:boolean;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  registerApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      firstNameError:false,
      firstNameErrorMessage:"",
      lastName: "",
      lastNameError:false,
      lastNameErrorMessage:"",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      registrationPassword:"",
      registrationEmail :"",
      registrationConfirmPassword:"",
      registrationUsername:"",
      privacyCheck:false,
      usernameError:false,
      usernameErrormessage:"",
      emailError:false,
      emailErrorMessage:"",
      passwordError:false,
      passwordErrorMessage:"",
      hasNumber:false,
      hasSpecialCharacter:false,
      isValidPasswordLength:false,
      showPassword:false,
      showConfirmPassword:false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveResponseHandler(from,message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async receiveResponseHandler(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );      
      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId ===this.registerApiCallId ){
          this.signUpResponseHandler(responseJson)
        }
      }
    }
  }
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };


  handleChangeUsername(event:ChangeEvent<HTMLInputElement>){
    this.setState({registrationUsername:event.target.value})
  }

  handleChangeFirstName(event:ChangeEvent<HTMLInputElement>){
    if( /^[A-Za-z]*$/.test(event.target.value)){
      this.setState({firstName:event.target.value})
    }
  }

  handleChangeLastName(event:ChangeEvent<HTMLInputElement>){
    if( /^[A-Za-z]*$/.test(event.target.value)){
      this.setState({lastName:event.target.value})
    }
  }

  handleChangeEmail(event:ChangeEvent<HTMLInputElement>){
    this.setState({registrationEmail:event.target.value})
  }
  validatePassword(password:string) {
    const validLength = password.length>=8;
    const isSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
    const isNumber= /\d/.test(password)
    this.setState({isValidPasswordLength:validLength, hasSpecialCharacter:isSpecialCharacter, hasNumber:isNumber})
  }
  handleChangePassword(event:ChangeEvent<HTMLInputElement>){
    this.setState({registrationPassword:event.target.value},()=>{
      this.validatePassword(event.target.value)
    })
  }

  handleChangeConfirmPassword(event:ChangeEvent<HTMLInputElement>){
    this.setState({registrationConfirmPassword:event.target.value})
  }

  validateRegistrationData():boolean {
    const isEmptyUsername = this.state.registrationUsername.length<1
    const isFirstNameEmpty=this.state.firstName.length<1
    const isLastNameEmpty=this.state.lastName.length<1
    const emptyUsernameError = isEmptyUsername?"Username required":"";
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = emailRegex.test(this.state.registrationEmail);
    const invalidEmailMessage = !isValidEmail ? 'Invalid email address' :"";
    let invalidPasswordMessage = ""
    let errorInPassword=false;
    if(!this.state.registrationPassword && !this.state.registrationConfirmPassword){
      invalidPasswordMessage ="Password required";
      errorInPassword=true;
    }else if(this.state.registrationPassword !== this.state.registrationConfirmPassword){
      invalidPasswordMessage = "Password Not matched";
      errorInPassword=true;
    }else{
      errorInPassword = !this.state.hasNumber || !this.state.hasSpecialCharacter || !this.state.isValidPasswordLength
    }
    
    this.setState({
      usernameError:isEmptyUsername,
      usernameErrormessage:emptyUsernameError,
      firstNameError:isFirstNameEmpty,
      firstNameErrorMessage:isFirstNameEmpty ? "Try again with a valid  Name.":"",
      lastNameError:isLastNameEmpty,
      lastNameErrorMessage:isLastNameEmpty ? "Try again with a valid  Name.":"",
      emailError:!isValidEmail,
      emailErrorMessage:invalidEmailMessage,
      passwordErrorMessage:invalidPasswordMessage,
      passwordError: errorInPassword,
    })
    if(isEmptyUsername ||!isValidEmail ||errorInPassword || isFirstNameEmpty || isLastNameEmpty)
      return false
    return true
  }

  handleRegister(){ 
    if(this.validateRegistrationData()){
      this.doSignUp()
    }
  }

  doSignUp(){        
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };  

    const httpBody = {
      data:{
        type: "email_account",
        attributes:{
           "user_name": this.state.registrationUsername,
           "first_name": this.state.firstName,
           "last_name":this.state.lastName,
           "email":this.state.registrationEmail,
           "password":this.state.registrationPassword,
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.registerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.registerUserEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  signUpSuccessResponseHandler(response:SignUpSuccessResponse){
      setStorageData('authToken', response.meta.token)
      setStorageData('userId',response.data.id);
      setStorageData('userData', response.data)
      toast.success("Sign Up Successful!",{
        autoClose: 3000,
        closeOnClick: false,
        draggable: false,
        transition: Slide,
      }) 
      this.props.navigation.navigate("Customisableusersubscriptions")    
  }
  signUpErrorResponseHandler(errorsResponse:SignUpErrorResponse){
    if(errorsResponse.username){
      toast.error(errorsResponse.username[0])
    }
    if( errorsResponse.email){
      toast.error(errorsResponse.email[0])
    }
  }
  signUpResponseHandler(response:SignUpResponse){
    if(!!response.data){
      this.signUpSuccessResponseHandler(response)
    }
    if(response.errors){
      this.signUpErrorResponseHandler(response.errors)
    }
  }
  sendCloseFormMessage() {
    const message: Message = new Message(getName(MessageEnum.closeSignUpFormMessage));
    message.addData(getName(MessageEnum.closeSignUpFormMessage),'Close Form')
    runEngine.sendMessage(getName(MessageEnum.closeSignUpFormMessage), message)
  }
  sendOpenLoginFormMessage() {
    const message: Message = new Message(getName(MessageEnum.openLogInFormRegistrationMessage));
    message.addData(getName(MessageEnum.openLogInFormRegistrationMessage),'Open Login Form')
    runEngine.sendMessage(getName(MessageEnum.openLogInFormRegistrationMessage), message)
  }

  handleClickOnPrivacy(){window.open("/PrivacyAndPolicy", '_blank')}
  handleClickOnTermsAndConditions(){window.open("/TermsConditions", '_blank')}
  // Customizable Area End
}
