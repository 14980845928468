export const logoImage = require('../assets/dataAiLogo.svg').default;
export const myProfileIcon = require('../assets/profile.png').default;
export const businessIcon = require('../assets/business.png').default;
export const homeIcon = require('../assets/home.png').default;
export const settingIcon = require('../assets/settings.png').default;
export const latestUpdateIcon = require('../assets/latestupdate.png').default;
export const helpCenterIcon = require('../assets/helpcenter.png').default;
export const logoutIcon = require('../assets/logout.png').default;
export const subcribeFileIcon = require('../assets/subcriptionfile.png').default;
export const rightArrowIcon = require('../assets/rightarrow.png').default;
export const downArrowIcon = require('../assets/arrowdown.png').default;
export const closeIcon = require('../assets/closeIcon.png').default;
export const contentCreationICon = require('../assets/contentCreation.svg').default;
export const userEngagementTool = require('../assets/user_engagement_tool.png').default;
