// Customizable Area Start
import React from 'react';
import CampaingnResultController, { Props } from "./CampaignsResultsController";
import { Box, Button, Typography, styled } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {backArrow, editIcon, instagramColor, facebookColor, noteICon, inboxIcon, fileIcon} from './assets'
import PostCard from './components/PostCard';
import BreadcrumbNavigationPage from '../../breadcrumbnavigation/src/BreadcrumbNavigationPage.web';

export default class CampaignsResults extends CampaingnResultController{
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start


  icons = (name:string) => {
    if(name.toLocaleLowerCase().includes("facebook")){
      return facebookColor;
    } else if(name.toLocaleLowerCase().includes("instagram")){
      return instagramColor;
    } else if(name.toLocaleLowerCase().includes("blogs")){
      return noteICon;
    } else {
      return inboxIcon
    }
  }

  txtStyle = (name: string) => {
    if(name.toLocaleLowerCase().includes("facebook")){
      return {...webStyle.f16w4, color:'#1977F3', textTransform:'capitalize' };
    } else if(name.toLocaleLowerCase().includes("instagram")){
      return {...webStyle.f16w4, color:'#7153B8', textTransform:'capitalize' };
    } else {
      return {...webStyle.f16w4, textTransform:'capitalize'}
    }
  } 

  renderViewAllPost = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px", padding: "32px" }}>
        <StyledHeadingContainer>
          <Box display="flex" alignItems="center" justifyContent="Center" gap="12px">
            <img src={backArrow} onClick={() => { this.setState({ render: 'results', viewAllFor:'' }) }} data-test-id="back-to-posts" />
            <Typography sx={webStyle.pageHeading}>Back to Campaign</Typography>
            <img src={editIcon} />
          </Box>
        </StyledHeadingContainer>
        <Box display="flex" flexDirection="column" gap="8px">
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" gap="8px" sx={{ padding: '12px 0' }}>
              <img src={this.icons(this.state.viewAllFor)} />
              <Typography sx={this.txtStyle(this.state.viewAllFor)}>{`${this.state.viewAllFor}\u00A0(${this.state.postsByPlateforms[this.state.viewAllFor].count})`}</Typography>
            </Box>
          </Box>
          <Box display="flex" gap="12px" flexWrap="wrap">
            {this.state.postsByPlateforms[this.state.viewAllFor].posts.map((post, i) => {
              return (
                <PostCard key={i} onRename={this.handleRenamePost.bind(this)} onDelete={this.handleDeletePost.bind(this)} onEdit={this.handleEditPost.bind(this)} onClick={() => { }} post={post} />
              );
            })}
          </Box>
        </Box>
      </Box>)
  }

  renderResults = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px", padding: "32px" }}>
        <StyledHeadingContainer>
          <Box display="flex" alignItems="center" justifyContent="Center" gap="12px">
            <img src={backArrow} data-testId="goToCampaigns" onClick={() => { this.navigateBackToCampaignPage() }} />
            <Typography sx={webStyle.pageHeading}>{this.campaignName}</Typography>
            <img src={editIcon} />
          </Box>
          <Box display="flex" alignItems="center">
            <Box sx={webStyle.moveToFolder}>
              <Typography sx={webStyle.f16} style={{ color: '#4D4D4D' }}>Move to</Typography>
              <img src={fileIcon} alt="" width="24px" height="24px" />
            </Box>
            <Button sx={webStyle.addContentBtn} endIcon={<AddIcon sx={{ fontSize: "16px" }} />} onClick={() => { this.goToAddContentPage() }} data-test-id="addContentBtn">
              Add Content
            </Button>
          </Box>
        </StyledHeadingContainer>
        <Typography sx={webStyle.resultHeading}>Results ({this.state.totalPosts})</Typography>

        {Object.entries(this.state.postsByPlateforms).map(([platformName, details], index) => {
          return (
            <Box display="flex" flexDirection="column" gap="8px" key={index} data-test-id={`posts-group-${index}`}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" gap="8px" sx={{ padding: '12px 0' }}>
                  <img src={this.icons(platformName)} />
                  <Typography sx={this.txtStyle(platformName)}>{`${platformName}\u00A0(${details.count})`}</Typography>
                </Box>
                <Typography sx={webStyle.f16} style={{ cursor: 'pointer', color: '#4D4D4D' }} onClick={() => { this.setState({ render: 'viewAllPosts', viewAllFor: platformName }) }} data-test-id={`viewall-btn-${index}`}>View all</Typography>
              </Box>
              <Box display="flex" gap="12px" overflow="hidden" flexWrap="wrap" height="300px">
                {details.posts.map((post, i) => {
                  return (
                    <PostCard key={i} post={post} onRename={this.handleRenamePost.bind(this)} onDelete={this.handleDeletePost.bind(this)} onEdit={this.handleEditPost.bind(this)} onClick={() => { }} />
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>)
  }

  render(){
    return (
        <BreadcrumbNavigationPage navigation={this.props.navigation}>
        <>
          {this.state.render === "results" && this.renderResults()}
          {this.state.render === "viewAllPosts" && this.renderViewAllPost()}
        </>
        </BreadcrumbNavigationPage>
    );
  }


}

const StyledHeadingContainer = styled(Box)({
  display: "flex", 
  justifyContent:"space-between",
  alignItems:"center",
  width:"100%",
  '@media (max-width: 646px)': {
   flexDirection:"column",
   alignItems:"start",
   justifyContent:"center",
   gap:"12px"
  },
  '@media (max-width: 600px)': {
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between",
   },
   '@media (max-width: 463px)': {
    flexDirection:"column",
    alignItems:"start",
    justifyContent:"start",
    gap:"12px"
   },
})

const webStyle = {
  pageHeading:{
    fontSize:"18px",
    fontWeight:600,
    fontFamily:"Open Sans",
    color:"#4D4D4D"
  },
  resultHeading:{
    fontSize:'24px',
    fontWeight:600,
    fontFamily:'Open sans',
    color:'#4D4D4D'
  },
  facebookTxt:{
    fontSize:'16px',
    fontWeight:400,
    color:"#1977F3",
    fontFamily:'Open sans'
  },
  instagramTxt:{
    fontSize:'16px',
    color: '#7153B8',
    fontWeight:400,
    fontFamily:'Open sans'
  },
  f16: {
    fontSize:'16px',
    fontWeight:600,
    color:'#000000',
    fontFamily:'Open sans'
  },
  f16w4: {
    fontSize:'16px',
    fontWeight:400,
    color:'#4D4D4D',
    fontFamily:'Open sans'
  },
  addContentBtn:{
    textTransform: 'none',
    color: "#FFFFFF",
    fontSize:"16px",
    fontWeight:600,
    fontFamily:"Open Sans",
    padding:'10px',
    background:'#000000',
    borderRadius:"8px",
    "&:hover":{
        background:'#000000',
    }
  },
  moveToFolder: {
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
    gap:"8px",
    marginRight:'40px'
  }
}

  // Customizable Area End
