import React from "react";
import {
    Box,
} from "@mui/material";
import { logoImage, } from '../assets'
const Header = () => {
    return (
        <header>
            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "0px 24px 0px 10px" }}>
                <img src={logoImage} />
                <Box ><span style={webStyles.headerLoginSignUpButton}>Sign Up</span> / <span style={webStyles.headerLoginSignUpButton}>Log in</span></Box>
            </Box>
        </header>
    )
}
export default Header;
const webStyles = {
    headerLoginSignUpButton: {
        fontFamily: "Open Sans",
        fontSize: "18px",
        fontWeight: 600
    },
}