import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: object;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    contentText: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class GenerateContentController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    authToken: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            contentText: ""
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }


    // Customizable Area Start
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
    }
    // Customizable Area End

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.authToken = await getStorageData('authToken');
    }

    handleGenerateContent = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ contentText: event.target.value })
    }

    handleChatPage = () => {
        const { contentText } = this.state;
        if (contentText) {
            this.goToChatPage();
        }
    }

    goToChatPage = () => {
     if(this.authToken){
         const contentQuestion = this.state.contentText
         const chatContent: Message = new Message(
             getName(MessageEnum.navigationGenerateContentMessage)
         );
         chatContent.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
         chatContent.addData(getName(MessageEnum.generateContent), contentQuestion);
         this.send(chatContent);
     } else {
        toast.error("Something went wrong, Please try again later!")
     }
    }

    startGuided = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "GuidedWalkthrough");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    // Customizable Area End
}