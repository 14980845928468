Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getMessageResponseFromJarvysEndPont="/bx_block_chatgpt3/chatgpt";
exports.jarvysHeadMsg = "JARVYS AI";
exports.platformMessgae = "To continue the conversation with Jarvys you will need to log in or sign up to the DatAI platform";
exports.signupText = "Sign up";
exports.loginText = "Log in";
// Customizable Area End