// Customizable Area Start
import React from "react";

import { Box, Typography, Slider, Link } from "@mui/material";
import NewCommunityManagerController, {
    Props,

} from "./NewCommunityManagerController.web";
import { Checkbox, Dialog, DialogContent, styled } from "@material-ui/core";
import {questionMarkIcon, uploadFileIcon } from "./assets";
import FileUpload from "../../../components/src/FileUpload";
import BreadcrumbNavigationPage from '../../breadcrumbnavigation/src/BreadcrumbNavigationPage.web';


export default class NewCommunityManager extends NewCommunityManagerController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        
        return (
            <>
            <BreadcrumbNavigationPage navigation={this.props.navigation}>
                <Box sx={webStyles.wrapper}>

                    <Box><Typography sx={webStyles.greenTxt}>{this.state.isEditPage ? 'Edit' : 'Create Your'} Community Manager</Typography></Box>

                    <input 
                        placeholder="Name" 
                        style={webStyles.inputStyle}
                        ref={this.inputRef}
                        value={this.state.name} 
                        onChange={(e: { target: { value: any; }; }) => { this.handleNameChange(e.target.value) }} 
                        data-test-id='handleNameChange' 
                        id="outlined-basic" 
                    />
                    {
                        this.state.isNameError && <Typography style={webStyles.nameErrorMsg}>Please enter community name</Typography>
                    }

                    <Box>
                        <Typography sx={webStyles.h5}>Communication mode <img style={{ marginRight: "5px", marginBottom: "-7px" }} src={questionMarkIcon} alt="" /> </Typography>
                        <Slider sx={webStyles.slider} min={0} max={4} value={this.state.sliderValue} data-test-id='handleSliderChange' onChange={this.handleSliderChange} />
                        <Box display="flex" sx={{ justifyContent: 'space-between' }}>
                            <Typography sx={webStyles.t14} style={{ color: '#4D4D4D' }}>Fun</Typography>
                            <Typography sx={webStyles.t14} style={{ color: '#4D4D4D' }}>Serious</Typography>
                        </Box>
                    </Box>

                    <Box>
                        <Typography sx={webStyles.t14} style={{ color: '#4D4D4D' }}>Add your website link</Typography>
                        <input style={webStyles.inputStyle} value={this.state.web_link} data-test-id='handlechange' onChange={(e) => this.handlewebsiteChange(e.target.value)} id="outlined-basic" placeholder="Paste it here" />
                    </Box>

                    <Box>
                        <FileUpload
                            title="Upload your brand guidelines"
                            fileList={this.state.guidelineFiles}
                            onFileChange={this.handleGuidelineFileChange}
                            onRemoveFile={this.removeGuidelineFile}
                            uploadFileIcon={uploadFileIcon}
                            inputId="brand-guidelines"
                            webStyles={webStyles}
                        />
                        <Typography style={{marginTop: '36px'}}></Typography>
                        <FileUpload
                            title="Upload your product prices"
                            fileList={this.state.productPriceFiles}
                            onFileChange={this.handleProductPriceFileChange}
                            onRemoveFile={this.removeProductPriceFile}
                            uploadFileIcon={uploadFileIcon}
                            inputId="product-prices"
                            webStyles={webStyles}
                        />
                    </Box>

                    <Box>
                        <Typography sx={webStyles.h5}>Custom instructions</Typography>
                        <textarea style={webStyles.textAresStyle} name="" id="" value={this.state.custom_instrution} data-test-id='handlechange' onChange={(e) => this.handleDescChange(e.target.value)} placeholder="This can be a multiline placeholder"></textarea>
                    </Box>
                    <Box>
                        <Typography style={webStyles.connectToText}>Connect to</Typography>
                        <Box style={!this.state.isSocialAccountUsed ? webStyles.socialBoxAccount : webStyles.socialBox}>
                            {this.state.socialMediaData.map((platform) => (
                                <Box
                                    key={platform.id}
                                    data-test-id='handleToggle'
                                    onClick={() => this.handleToggle(platform.id)}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "0 0px 0 8px",
                                        border: `2px solid ${platform.connected ? "#009460" : "#ccc"}`,
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                    }}
                                >
                                    {platform.connected ? (
                                        <Checkbox
                                            checked={platform.connected}
                                            style={{
                                                color: "#009460",
                                                padding: 0,
                                                marginRight: "5px",
                                            }}
                                        />
                                    ) : (
                                        <img style={{ marginRight: "5px" }} />
                                    )}
                                    <img
                                        src={platform.icon}
                                        alt={platform.name}
                                        style={{ width: "40px", height: "40px" }}
                                    />
                                </Box>
                            ))}
                        </Box>
                        {this.state.isSocialAccountUsed && <Typography style={webStyles.isSocialAccountUsedStyle}>Social account already used in community.</Typography>}

                        <Typography sx={webStyles.h5}>
                            Don't see the channels you're looking for? Manage your available channels in
                            <Link href="#" sx={{ color: '#009460', textDecoration: 'underline', marginLeft: '12px' }}>
                                Connected Accounts
                            </Link>
                        </Typography>
                    </Box>

                    <Box style={webStyles.btnBox}>
                        <button style={webStyles.cancleBtn} data-test-id='handleOpenModal' onClick={this.handleOpenModal} >Cancel</button>
                        <button data-test-id='handleCreateCommunity' style={this.state.isEditPage ? webStyles.fillSaveButton :webStyles.fillButton} onClick={this.handleCreateCommunity}> {this.state.isEditPage ? 'Save' : 'Create your Community Manager'} </button>
                    </Box >

                    <CustomDialog
                        onClose={this.handleCloseModal}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.isModalOpen}
                    >
                        <DialogContent dividers>
                            <Typography style={webStyles.cancelTitle}>Are you sure you want to cancel all changes?</Typography>
                            <Typography style={webStyles.cancelWarningText}>Community Manager will not be saved</Typography>
                            <Box style={webStyles.cancelButtonBox}>
                                <button data-test-id='handleDelete' onClick={this.handleCancelChanges} style={webStyles.continueBtn}>Continue</button>
                                <button data-test-id='btnCloseDelteModal' onClick={this.handleCloseModal} style={webStyles.cancelBtn}>Cancel</button>
                            </Box>
                        </DialogContent>
                    </CustomDialog>
                </Box>
            </BreadcrumbNavigationPage>
            </>

        );

    }
}

export const webStyles = {
    wrapper: {
        margin: '24px',
        display: 'flex',
        flexDirection: 'column' as 'column',
        gap: '24px',
    },
    connectToText: {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        color: '#4D4D4D',
        marginBottom: '8px',
    },
    socialBox: {
        display: "flex", gap: "10px", 
    },
    socialBoxAccount: {
        display: "flex", gap: "10px", marginBottom: '24px'
    },
    isSocialAccountUsedStyle: {
        marginBottom: '24px',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#DC143C',
    },
    socialIcon: {
        width: '40px',
        height: '40px',
    },
    slider: {
        color: '#059669',
        '& .MuiSlider-track': {
            borderRadius: 4,
            backgroundColor: '#059669',
        },
        '& .MuiSlider-thumb': {
            height: '24px',
            width: '24px',
            backgroundColor: 'transparent',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            '&:before': {
                content: '"\\1F610"',
                fontSize: '24px'
            }
        },
        '& .MuiSlider-rail': {
            height: 8,
            backgroundColor: '#fff',
            border: '1px solid #059669',
        },
    },

    browseFileTxt: {
        textAlign: 'center' as 'center',
        width: '121px',
        padding: '10px 16px 10px 16px',
        borderRadius: '8px',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        border: '1px solid #B3B3B3',
        color: '#4D4D4D',
        marginTop: "32px",
    },
    btnBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        marginBottom: '90px',
    },
    cancleBtn: {
        width: '177px',
        padding: '10px 16px 10px 16px',
        borderRadius: '8px',
        border: '1px solid #059669',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#059669',
        background: 'none',
        marginRight: '16px',
    },
    fillButton: {
        border: 'none',
        width: 'fit-content',
        padding: '12px 15px 12px 15px',
        borderRadius: '8px',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#FFFFFF',
        background: '#059669',
    },
    fillSaveButton: {
        borderRadius: '8px',
        fontFamily: 'Open Sans',
        width: '177px',
        border: 'none',
        padding: '12px 15px 12px 15px',
        fontSize: '16px',
        fontWeight: 600,
        background: '#059669',
        color: '#FFFFFF',
    },
    addFiles: {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#059669',
    },
    dotedBox: {
        height: '298px',
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px dashed #737373',
        borderRadius: '12px',
    },
    uploadFileIcon: {
        marginBottom: "32px"
    },
    textAresStyle: {
        borderRadius: '8px',
        border: '1px solid #DBD8D8',
        height: '183px',
        width: '100%'
    },
    inputStyle: {
        width: '100%',
        borderRadius: '8px',
        outline: 'none',
        height: '48px',
        border: '1px solid #DDDDDD',
        paddingLeft: '8px'
    },
    nameErrorMsg: {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#DC143C',
        marginTop: '-20px'
    },
    h5: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        color: '#4D4D4D'
    },
    t14: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        color: '#B0B0B0'
    },
    greenTxt: {
        color: '#059669',
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: '32px'
    },
    cancelTitle: {
        fontFamily: 'Open Sans',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '27.24px',
        color: '#059669',
        textAlign: 'center' as 'center',
    },
    cancelWarningText: {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '21.79px',
        color: '#4D4D4D',
        marginTop: '16px',
        textAlign: 'center' as 'center',
    },
    cancelButtonBox: {
        marginTop: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    continueBtn: {
        width: '100%',
        marginRight: "12px",
        padding: '10px 16px 10px 16px',
        borderRadius: '8px',
        background: '#059669',
        border: 'none',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#FFFFFF',
        cursor: 'pointer',
    },
    cancelBtn: {
        width: '100%',
        marginLeft: "12px",
        padding: '10px 16px 10px 16px',
        borderRadius: '8px',
        background: 'none',
        border: '1px solid #059669',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#059669',
        cursor: 'pointer',
    },

    container: {
        width: '50%',
        margin: '50px auto',
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
    },
    label: {
        display: 'block',
        border: '2px dashed #ccc',
        padding: '20px',
        textAlign: 'center' as const,
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    uploadBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center' as const,
    },
    icon: {
        fontSize: '40px',
        color: '#666',
    },
    text: {
        fontSize: '16px',
        marginTop: '10px',
        color: '#666',
    },
    subText: {
        fontSize: '12px',
        color: '#aaa',
        marginTop: '5px',
    },
    hiddenInput: {
        display: 'none',
    },

    fileList: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        flexWrap: 'wrap' as 'wrap',
        gap: '10px',
    },
    fileItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        backgroundColor: '#fff',
        width: 'fit-content',
    },
    fileIcon: {
        width: '20px',
        marginRight: '10px',
    },
    fileName: {
        color: '#007bff',
        textDecoration: 'underline',
        marginRight: '10px',
    },
    removeFile: {
        cursor: 'pointer',
        color: '#ff4d4d',
        marginLeft: 'auto',
        marginRight: '10px',
    },
    downloadIcon: {
        cursor: 'pointer',
        color: '#007bff',
    },
}

const CustomDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        borderRadius: "24px",
        boxSizing: 'border-box',
        width: '551px',
    },
    '& .MuiDialogContent-root': {
        padding: '40px 40px 40px 40px',
    },
});

// Customizable Area End
