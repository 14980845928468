export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const searchIcon = require('../assets/searchIcon.svg').default;
export const starIcon = require('../assets/starIcon.svg').default;
export const seoQuickTips = require('../assets/seo_quick_tips.svg').default;
export const seoBestPractices = require('../assets/seo_best_practices.svg').default;
export const learnSeoBasic = require('../assets/learn_seo_basics.svg').default;
export const seoStrategies = require('../assets/seo_strategies.svg').default;
export const editPenIcon = require("../assets/edit_pen_icon.svg").default;
export const backArrowIcon = require("../assets/back_arrow_icon.svg").default;
export const speakerIcon = require("../assets/speaker_icon.svg").default;
export const regenerateIcon = require("../assets/regenerate_icon.svg").default;
export const dislikeIcon = require("../assets/dislike_icon.svg").default;
export const sendIcon = require("../assets/send_icon.svg").default;