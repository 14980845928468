import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { View, StyleSheet } from "react-native";

import { searchIcon, starIcon, seoQuickTips, seoBestPractices, learnSeoBasic, seoStrategies, editPenIcon, backArrowIcon, speakerIcon, regenerateIcon, dislikeIcon, sendIcon } from "./assets";
import {
  styled,
  Box,
  TextField,
  Typography as MuiTypography, Button as MuiButton, IconButton
} from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import BreadcrumbNavigationPage from '../../breadcrumbnavigation/src/BreadcrumbNavigationPage.web';

// Customizable Area End

import CfmarkyaiapiintegrationController, {
  Props,
  configJSON,
} from "./CfmarkyaiapiintegrationController";

export default class Cfmarkyaiapiintegration extends CfmarkyaiapiintegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderGenerateContent = () => {
    const buttons = [
      { name: configJSON.seoQuickTips, icon: seoQuickTips },
      { name: configJSON.seoBestPractices, icon: seoBestPractices },
      { name: configJSON.learnSeoBasics, icon: learnSeoBasic },
      { name: configJSON.seoStrategies, icon: seoStrategies }];
    return (
      <>
        <MainContainer data-testid="seoRecommendations">
          <GenerateBox>
            <HeadingContent>{configJSON.seoRecommendations}</HeadingContent>
            <ExampleContent>{configJSON.exampleContent}</ExampleContent>
            <InputFieldBox>
              <img src={searchIcon} className="search_img" />
              <TextField data-test-id="txtInput" placeholder={configJSON.submitYourURL} value={this.state.contentText} onChange={this.handleGenerateContent} className='textField' />
              <Box data-testid="check-seo-button" onClick={this.handleChatPage} className='textFieldBtn'>{configJSON.checkSeo}<img
                src={starIcon}
                className="star_img" /></Box>
            </InputFieldBox>
            <ContentHeading>{configJSON.usefulContent}</ContentHeading>
            <StartPointContainer>
              {buttons.map((data, index) => {
                return (
                  <StartPointBox key={index} style={this.state.selectedBtn === index + 1 ? {backgroundColor:'#000'} : {}} onClick={() => { this.handleSelectedBtn(data.name, index + 1) }} data-testid={`seo-button-${index}`}>
                    <img src={data.icon} className="start_img" data-testid={`start-img-${index}`} style={this.state.selectedBtn === index + 1 ? {filter:'invert(100%)'} : {filter:'none'}} />
                    <MuiTypography className="start_point_content" style={this.state.selectedBtn === index + 1 ? {color:'#fff'} : {color:'#4D4D4D'}}>{data.name}</MuiTypography>
                  </StartPointBox>
                );
              })}
            </StartPointContainer>
          </GenerateBox>
        </MainContainer>
      </>
    )
  }

  renderChatSideBar = () => {
    return (
      <>
        <ChatSideBarWrapper open={this.state.openChatHistory} style={{}}>
          <TopButtonSidebar>
            <MuiButton data-testid='newChatBtn' sx={webStyle.newChatButton} endIcon={<AddIcon sx={{ fontSize: "16px" }} />}>
              New chat
            </MuiButton>
            <ClearIcon className="crossIcon" onClick={() => {this.setState({openChatHistory: false})}} data-testid="close-sidebar-btn"/>
          </TopButtonSidebar>
          <PrevChatWrapper>
            <DateWiseChatWrapper>
              <MuiTypography className="date">Today</MuiTypography>
              <MuiButton sx={webStyle.currChatButton} endIcon={<MoreVertIcon sx={{fontSize:'16px'}}/>}>
                New chat
              </MuiButton>
            </DateWiseChatWrapper>
            <DateWiseChatWrapper>
              <MuiTypography className="date">Yesterday</MuiTypography>
              <MuiButton sx={webStyle.prevChatButton} endIcon={<MoreVertIcon sx={{fontSize:'16px'}}/>}>
                Chat Name examp...
              </MuiButton>
              <MuiButton sx={webStyle.prevChatButton} endIcon={<MoreVertIcon sx={{fontSize:'16px'}}/>}>
                Chat Name examp...
              </MuiButton>
            </DateWiseChatWrapper>
          </PrevChatWrapper>
        </ChatSideBarWrapper>
      </>
    )
  }

  renderChatScreen = () => {
    return (
      <Box display="flex" data-testid='chats-screen'>
        {this.renderChatSideBar()}
        <ChatWrapper>
          <ChatHeader>
            <img src={backArrowIcon} alt=""  onClick={() => {this.setState({isChatPage:false})}} data-testid="back-arrow-btn"/>
            <MuiTypography className="headerTxt">www.trainer.com</MuiTypography>
            <img src={editPenIcon} alt="" />
            <ChatSidebarMenuIcon>
              <MenuIcon onClick={() => {this.setState({openChatHistory: !this.state.openChatHistory})}} data-testid="menu-icon-btn"/>
            </ChatSidebarMenuIcon>
          </ChatHeader>
          <ChatBubblesWrapper>
            <Box>
              <ChatRightBubble>
                <MuiTypography className="yourQuestion">Your question</MuiTypography>
                <MuiTypography className="rightBubble">www.trainer.com</MuiTypography>
              </ChatRightBubble>
            </Box>
            <ChatLeftBubble>
              <MuiTypography className="name">JARVYS's answer</MuiTypography>
              <MuiTypography>
                SEO Analysis of www.trainer.com:
                Title & Meta Descriptions:
                The title tag is optimized with key terms related to personal training services in Colombia. However, it could be shortened slightly for better readability and click-through rates.
                Meta descriptions are informative but could be enhanced with more persuasive language and CTAs (Calls to Action) to improve click-through rates.
                Keywords & Content:
                The site targets a broad range of keywords related to personal training in various Colombian cities.
                There is good keyword usage, but the content can be further optimized by focusing on long-tail keywords and ensuring keyword density isn't too high to avoid penalties.
                On-Page Elements:
                Headings (H1, H2, etc.) are well-structured, but some pages could benefit from better keyword integration in subheadings.
                Alt text is used for images, but some images lack descriptive alt tags that could help in image search rankings.
                Technical SEO:
                The site speed appears reasonable, but improving it could enhance user experience and rankings.
                The website is mobile-friendly, which is essential for SEO, considering the increasing number of mobile users.
              </MuiTypography>
              <ChatBubbleIcons>
                <img src={speakerIcon} alt="" />
                <img src={regenerateIcon} alt="" />
                <img src={dislikeIcon} alt="" />
              </ChatBubbleIcons>
            </ChatLeftBubble>
          </ChatBubblesWrapper>
          <StyledInputField
            fullWidth
            id="outlined-adornment-password"
            type={'text'}
            placeholder='Enter your text'
            variant='outlined'
            InputProps={{
              endAdornment: <IconButton
                aria-label="toggle password visibility"
                data-test-id='btnSendMsg'
                edge="end"
              >
                <img src={sendIcon} alt="" />
              </IconButton>
            }}
          />
        </ChatWrapper>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <BreadcrumbNavigationPage navigation={this.props.navigation}>
          {!this.state.isChatPage ? this.renderGenerateContent() : this.renderChatScreen()}
        </BreadcrumbNavigationPage>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const MainContainer = styled(Box)({
  backgroundColor: "#FFFDD8",
  padding: "100px",
  overflow: "scroll",
  "@media (max-width: 1000px)": {
    padding: "50px 20px"
  },
  "@media (max-width: 600px)": {
    padding: "50px 20px"
  },
})

const GenerateBox = styled(Box)({
  width: "100%"
})

const HeadingContent = styled(Box)({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "32px",
  color: "#4D4D4D",
  '@media (max-width: 646px)': {
    fontSize:'28px',
  },
  '@media (max-width: 450px)': {
    fontSize:'24px',
  },
  '@media (max-width: 320px)': {
    fontSize:'20px',
  }
})

const ExampleContent = styled(Box)({
  padding: "",
  marginTop: "48px",
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "18px",
  color:'#4D4D4D',
  lineHeight:'24.51px',
  '@media (max-width: 646px)': {
    fontSize:'16px',
  },
  '@media (max-width: 450px)': {
    fontSize:'14px',
  },
  '@media (max-width: 320px)': {
    fontSize:'12px',
  }
})

const InputFieldBox = styled(Box)({
  width: "100%",
  height: "65px",
  marginTop: "48px",
  marginBottom: "80px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "8px",
  fontSize: "18px",
  border: "1px solid #DDDDDDDD",
  backgroundColor: "#FFFFFF",
  overflow: "hidden",
  cursor: "pointer",
  "& .textField": {
    fontSize: "18px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    border: "none !important",
    "& .css-igs3ac": {
      border: "none !important",
    },
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderStyle: "unset"
    }
  },
  "& .search_img": {
    height: "20px",
    marginLeft: "15px"
  },
  "& .textFieldBtn": {
    backgroundColor: "#000000",
    width: "200px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    justifyContent: "center",
    gap: "10px",
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "18px",
    cursor: "pointer"
  },
  "& .star_img": {
    height: "20px"
  },
  '@media (max-width: 450px)': {
    "& .textFieldBtn": {
      fontSize:'14px',
    }
  },
})

const ContentHeading = styled(Box)({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "18px",
  color: "#4D4D4D",
  marginBottom: "24px"
})

const StartPointContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: "20px"
})

const StartPointBox = styled(Box)({
  flex: "1 0 150px",
  border: "0.5px solid #000000",
  borderRadius: "20px",
  padding: "16px",
  textAlign: "center",
  cursor: "pointer",
  "& .start_img": {
    height: "32px",
    color: "#3D3D3D",
  },
  "& .start_point_content": {
    marginTop: "15px",
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "16px",
    color: "#4D4D4D",
  },
  "&:hover": {
    backgroundColor: "#000",
  },
  "&:hover .start_img": {
    height: "32px",
    filter: "invert(100%) !important"
  },
  "&:hover .start_point_content": {
    color: "#FFFFFF !important",
  },
});

const ChatSideBarWrapper = styled(Box)(({ open }: { open: boolean }) => ({
  overflow:'scroll',
  display: 'flex',
  flexDirection:'column',
  width: '275px',
  height:'86vh',
  padding:'16px',
  backgroundColor:'#F7F7F7',
  '@media (max-width: 1150px)':{
    display: open ? 'block' : 'none',
    position:'absolute',
    height:'100vh',
    top:0,
    zIndex:100
  }
}));

const TopButtonSidebar = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  alignItems: "center",
  ".crossIcon":{
  },
  '@media (min-width: 1150px)':{
    ".crossIcon":{
      display:'none'
    },
  }
})

const ChatSidebarMenuIcon = styled(Box)({
  marginLeft:'auto',
  display:'none',
  '@media (max-width: 1150px)':{
    display: 'block',
  }
})

const PrevChatWrapper = styled(Box)({
  marginTop:'32px',
  display:'flex',
  flexDirection:'column',
  gap:'24px',
})

const DateWiseChatWrapper = styled(Box)({
  display:'flex',
  flexDirection:'column',
  gap:'4px',
  '.date':{
    marginBottom:'16px',
    fontSize:'16px',
    fontWeight: 400,
    fontFamilty:'Open sans',
    color: '#B3B3B3'
  }
})

const ChatWrapper = styled(Box)({
  display:'flex',
  flexDirection:'column',
  padding:'32px 32px 0 32px',
  width:'100%',
  height:'85vh',
  '@media (max-width: 1150px)':{
    display: 'block',
    padding:'12px 12px 0 12px'
  }
})

const ChatHeader = styled(Box)({
  display:'flex',
  gap:'16px',
  marginBottom:'80px',
  ".headerTxt":{
    fontSize:'18px',
    fontWeight:400
  }

})

const ChatBubblesWrapper = styled(Box)({
  display:'flex',
  flexDirection:'column',
  overflow:'scroll',
  gap:'16px',
  flexGrow:1,
})

const ChatRightBubble = styled(Box)({
  maxWidth:'520px',
  paddingRight:'8px',
  marginLeft:'auto',
  ".rightBubble": {
    marginLeft:'auto',
    backgroundColor:'#EFEFEF',
    borderRadius:'16px 0 16px 16px',
    position:'relative',
    padding:'8px 16px',
  },
  ".yourQuestion":{
    color:'#4D4D4D',
    fontSize:'16px',
    fontWeight:600,
    fontFamily:'Open sans',
    display:'flex',
    justifyContent:'end',
    marginBottom:'8px'
  },
  "@media (max-width: 800px)": {
    maxWidth:'420px'
  },
})

const ChatLeftBubble = styled(Box)({
  padding:'8px 16px',
  ".name":{
    fontSize:'16px',
    fontWeight:600,
    color:'#4D4D4D',
    fontFamily:'Open sans',
    marginBottom:'8px'
  }
})

const ChatBubbleIcons = styled(Box)({
  marginTop:'8px',
  display:'flex',
  gap:'14px'
})

const StyledInputField = styled(TextField)({
  marginBottom: "0px",
  marginTop:'12px',
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#B3B3B3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B3B3B3",
      borderWidth: "1px",
    },
  },
  "& .MuiInputBase-root":{
    fontFamily: 'Poppins',
    height: '66px',
    margin: 'auto',
    borderRadius: '100px',
    backgroundColor: "#FFFFFF",
    fontSize: '16',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderRadius: "100px",
    borderColor: "#B3B3B3",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#B3B3B3",
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: 'Poppins',
    color: '#B3B3B3',
    paddingLeft: '20px',
    paddingTop: "17px",
    "&::placeholder": {
      fontSize: "16",
      fontFamily: 'Poppins',
      color: '#B3B3B3',
    },
    "&:not(:placeholder-shown)": {
      fontFamily: 'Poppins',
      color: '#B3B3B3',
    },
  },
  "& .MuiInputBase-input[type='date']": {
    color:'red',
    textTransform:'uppercase',
    lineHeight: "19.2px",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    fontFamily: "Lato",
    color: "#DC2626",
  }, 
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B3B3B3"
  },
  
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':{
    border:"1px solid #FFFFFF",
  },
  "& .MuiFormHelperText-contained": {
    marginRight: "0px",
    marginLeft: "0px",
  },
});

const webStyle ={
  newChatButton:{
    flex:1,
    textTransform: 'none',
    color: "#FFFFFF",
    fontSize:"16px",
    fontWeight:600,
    fontFamily:"Open Sans",
    padding:'10px',
    background:'#000000',
    borderRadius:"8px",
    "&:hover":{
        background:'#000000',
    },
    "@media (max-width: 1150px)": {
      flex: '0 0 auto'
    },
  },
  currChatButton: {
    textTransform: 'none',
    color: "#404040",
    fontSize:"14px",
    fontWeight:600,
    fontFamily:"Open Sans",
    padding:'10px',
    borderRadius:"8px",
    border:'2px solid #4D4D4D',
    display:'flex',
    justifyContent:'space-between'
  },
  prevChatButton: {
    textTransform: 'none',
    color: "#404040",
    fontSize:"14px",
    fontWeight:600,
    fontFamily:"Open Sans",
    padding:'10px',
    borderRadius:"8px",
    display:'flex',
    backgroundColor:'#fff',
    justifyContent:'space-between'
  }
}
// Customizable Area End
