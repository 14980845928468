import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from 'react';
import { getStorageData } from "../../../framework/src/Utilities"


export interface ApiData{
    contentType: string;
    method: string;
    endPoint: string; 
    body?: {}
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: object;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    accessToken: string;
    error: string;
    accessData: object;
    isSwitch: {
        realSwitch: boolean,
        userSwitch: boolean,
        contentSwitch: boolean
    };
    isDisconnect: boolean;
    isShowBtn: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ConnectedAccountsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    instagramRef: React.RefObject<HTMLDivElement>;
    apiGetConnectedPlatformId: string = "";
    apiFetchTokenId: string = "";
    apiSaveTokenId: string = "";
    authToken: string = "";
    userId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            accessToken: "",
            error: "",
            accessData: {},
            isSwitch: {
                realSwitch: true,
                userSwitch: false,
                contentSwitch: true
            },
            isDisconnect: false,
            isShowBtn: true
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.instagramRef = React.createRef();
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(apiRequestCallId === this.apiGetConnectedPlatformId){
                if(responseJson.length > 0){
                    this.setState({isShowBtn:false});
                }
            }if(apiRequestCallId === this.apiFetchTokenId){
                this.saveToken(responseJson.token);
            } if(apiRequestCallId  === this.apiSaveTokenId){
                this.setState({isShowBtn:false});
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        const url = window.location.href;
        const params = new URLSearchParams(new URL(url).search);
        const code = params.get('code');
        if(code){
            this.fetchToken(code);
        }

        this.authToken = await getStorageData('authToken');
        this.userId = await getStorageData('userId');
        this.getConnectedPlatforms();
      }

    handleConnectButtonClick = () => {
        this.setState({isShowBtn : false});
    };
    responseInstagram = (response:object) => {
        this.setState({accessData : response})
     };

    handleCloseModal = () =>{
        this.setState({ isDisconnect: false});
      }

    handleOpenModal = () =>{
        this.setState({ isDisconnect: true});
      }
    
    handleDisconnect = () =>{
        this.setState({ isShowBtn: true });
        this.handleCloseModal();
    }
 
    handleAccountSwitch = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ 
            isSwitch: { 
              ...this.state.isSwitch, 
              [event.target.name]: event.target.checked 
            } 
          });
      };
    
    getConnectedPlatforms = () => {
        this.apiGetConnectedPlatformId = this.apiCall({
            contentType: configJSON.ApiContentTypeJson,
            method: "GET",
            endPoint: `/bx_block_settings/platforms`
          })
    }

    fetchToken = (code:string) => {
        this.apiFetchTokenId = this.apiCall({
            contentType: configJSON.ApiContentTypeJson,
            method: "POST",
            endPoint: `/bx_block_chatgpt3/thread_chatgpt/insta_access_token`,
            body:{
                "code":code
            }
          })
    }

    saveToken = (token: string) => {
        this.apiSaveTokenId = this.apiCall({
            contentType: configJSON.ApiContentTypeJson,
            method: "POST",
            endPoint: `/bx_block_settings/platforms#create`,
            body:{
                "platform":{
                    "platform_name":"instagram",
                    "access_token": token
                }
            }
          })
    }


    apiCall = (data: ApiData) => {
        const { contentType, method, endPoint, body } = data;
        const token =  localStorage.getItem("authToken") || "";
        const header = {
          "Content-Type": contentType,
          token: token,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        body &&
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
    // Customizable Area End
}